// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Molecules } from '@tls/treact-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToolMode as Mode } from 'model/Tools';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { changeToolMode } from 'store/reducers/projectToolSlice';
import IconErase from 'components/treactui-template/icons/IconErase';
import IconDraw from 'components/treactui-template/icons/IconDraw';
import useProjectLabels from 'hooks/useProjectLabels';

export default function ToolMode() {
  const { t } = useTranslation();
  const mode = useAppSelector(s => s.tools.mode);
  const { selectedMd5 } = useProjectLabels();
  const dispatch = useAppDispatch();

  const onClick = useCallback((mode: Mode) => () => dispatch(changeToolMode(mode)), [dispatch]);

  return (
    <Container>
      <span className='font-size_m font-weight_bold'>{t('project.label.tools.mode.title')}</span>
      <Molecules.SegmentedControl asAtom>
        <Molecules.SegmentedControl.SegmentedControlButton
          icon={<IconDraw width={'1.5rem'} />}
          onClick={onClick(Mode.Draw)}
          selected={mode === Mode.Draw}
          label={t('project.label.tools.mode.draw')}
          disabled={!selectedMd5}
        />
        <Molecules.SegmentedControl.SegmentedControlButton
          icon={<IconErase width={'1.5rem'} />}
          selected={mode === Mode.Erase}
          onClick={onClick(Mode.Erase)}
          label={t('project.label.tools.mode.erase')}
          disabled={!selectedMd5}
        />
      </Molecules.SegmentedControl>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 1rem;
`;
