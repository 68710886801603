// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Molecules } from '@tls/treact-ui';
import AddProjectButton from 'components/common/AddProjectButton';
import useAppPermissions from 'hooks/useAppPermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { EmptyState } = Molecules;

export default function NoProjects() {
  const { t } = useTranslation();

  const { hasCreateCDProjectPermission, hasCreateQCProjectPermission } = useAppPermissions();

  return (
    <Container>
      <EmptyStateContainer>
        <EmptyState
          iconType={'noData'}
          label={t(
            !hasCreateCDProjectPermission && !hasCreateQCProjectPermission
              ? 'projects.create.noLicense'
              : 'projects.noProjects'
          )}
        />
      </EmptyStateContainer>
      {hasCreateCDProjectPermission || (hasCreateQCProjectPermission && <AddProjectButton />)}
    </Container>
  );
}

const Container = styled.div`
  inset: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: 100%;
`;

const EmptyStateContainer = styled.div`
  width: 100%;
`;
