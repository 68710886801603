// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useMemo } from 'react';
import { COLORS, FLAGS, ICONS } from '@tls/treact-ui';
import styled, { css } from 'styled-components';
import FlagErrorInteraction from 'components/treactui-template/icons/FlagErrorInteraction';

const { IconClose } = ICONS;
const WarningFlag = FLAGS.COMPONENTS.warning;

interface Props {
  type: InfoBannerType;
  title: string;
  description: string;
  onClose?: () => void;
}

export enum InfoBannerType {
  error = 'error',
  warning = 'warning',
}

export default function InfoBanner({ type, title, description, onClose }: Readonly<Props>) {
  const flag = useMemo(() => {
    switch (type) {
      case InfoBannerType.error:
        return <FlagErrorInteraction width={'1.75rem'} />;
      case InfoBannerType.warning:
        return <WarningFlag width={'1.75rem'} />;
    }
  }, [type]);

  return (
    <Root type={type}>
      <Spacer />
      {flag}
      <span className='font-size_s font-weight_bold'>{title}</span>
      <span className='font-size_s'>{description}</span>
      <Spacer />
      <div style={{ paddingRight: '0.5rem' }}>
        {onClose && <IconClose className='button' width={'1rem'} onClick={onClose} />}
      </div>
    </Root>
  );
}

const Root = styled.section<{ type: InfoBannerType }>`
  height: 2.25rem;
  padding: 0.5rem;

  ${({ type }) => {
    switch (type) {
      case InfoBannerType.error:
        return css`
          background-color: ${COLORS.trred2.hex};
          color: ${COLORS.trwhite.hex};
        `;
      case InfoBannerType.warning:
        return css`
          background-color: ${COLORS.trblue0.hex};
          color: ${COLORS.trblack.hex};
        `;
    }
  }}

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;

  .button {
    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.trcolor1.hex};
    }
  }
`;
const Spacer = styled.div`
  flex: 1;
`;
