// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useSafContext } from '@tls/react-saf-ui';
import { ICONS, Molecules } from '@tls/treact-ui';
import IconExport from 'components/treactui-template/icons/IconExport';
import IconShare from 'components/treactui-template/icons/IconShare';
import IconUnlocked from 'components/treactui-template/icons/IconUnlocked';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import useProjectExportApi from 'hooks/useProjectExportApi';
import { useProjectLockApi } from 'hooks/useProjectLockApi';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store/hooks';
import { setDeleteId } from 'store/reducers/projectDeleteSlice';
import { setEditId, setIdToDuplicateFrom } from 'store/reducers/projectSettingsSlice';
import { setShareId } from 'store/reducers/projectShareSlice';
import { fetchProjects } from 'store/reducers/projectsSlice';
import { AppRoutes } from 'utilities/router/AppRoutes';

export type Option = {
  label: string;
  icon: JSX.Element;
  id: number;
  disabled: boolean;
};

const labelSelector = (t: TFunction) => (o: Option) => t(o.label);
const iconSelector = (o: Option) => o.icon;
const keySelector = (o: Option) => o.id;
const disabledSelector = (o: Option) => o.disabled;

type Props = {
  projectId: string;
  projectName?: string;
  lockedBy?: string;
};

const { IconEdit, IconDuplicate, IconLoading, IconDelete } = ICONS;

export default function Options({ projectId, projectName, lockedBy }: Readonly<Props>) {
  const { t } = useTranslation();

  const { currentUser } = useSafContext();

  const dispatch = useAppDispatch();
  const { loading, exportProject } = useProjectExportApi(projectId);
  const { loading: loadingUnlock, unlock } = useProjectLockApi();

  const navigate = useNavigate();

  const isLockedByUser = lockedBy && lockedBy === currentUser?.userName;
  const options: Option[] = useMemo(() => {
    const result = [
      /*{ icon: <ICONS.IconHistory width='1.125rem' />, id: 3, label: 'project.actions.history', disabled: true },*/
      { icon: <IconEdit width='1.125rem' />, id: 1, label: 'project.actions.edit', disabled: false },
      { icon: <IconDuplicate width='1.125rem' />, id: 2, label: 'project.actions.duplicate', disabled: false },
      {
        icon: loading ? <IconLoading /> : <IconExport width='1.125rem' />,
        id: 4,
        label: 'project.actions.export',
        disabled: loading,
      },
    ];

    if (isLockedByUser) {
      result.push({
        icon: loadingUnlock ? <IconLoading /> : <IconUnlocked width='1.125rem' />,
        id: 7,
        label: 'project.actions.unlock',
        disabled: loadingUnlock,
      });
    }
    result.push(
      { icon: <IconShare width='1.125rem' />, id: 6, label: 'project.actions.share', disabled: false },
      { icon: <IconDelete width='1.125rem' />, id: 5, label: 'project.actions.delete', disabled: false }
    );

    return result;
  }, [loading, isLockedByUser, loadingUnlock]);

  const unlockProject = useCallback(async () => {
    const unlocked = await unlock(projectId);
    ToastByStatus(
      unlocked ? 200 : 500,
      t('project.unlock.title', { projectName }),
      t('project.unlock.error', { projectName }),
      t('project.unlock.success', { projectName })
    );

    if (unlocked) {
      dispatch(fetchProjects());
      navigate(AppRoutes.Projects);
    }
  }, [unlock, projectName, projectId, navigate, t, dispatch]);

  const onClick = useCallback(
    (option: Option) => {
      switch (option.id) {
        case 1:
          dispatch(setEditId(projectId));
          break;
        case 2:
          dispatch(setIdToDuplicateFrom(projectId));
          break;
        case 4:
          exportProject();
          break;
        case 5:
          dispatch(setDeleteId(projectId));
          break;
        case 6:
          dispatch(setShareId(projectId));
          break;
        case 7:
          unlockProject();
          break;
      }
    },
    [dispatch, exportProject, projectId, unlockProject]
  );

  return (
    <Molecules.FunctionMenu
      className='moveRight'
      options={options}
      labelSelector={labelSelector(t)}
      iconSelector={iconSelector}
      keySelector={keySelector}
      disabledSelector={disabledSelector}
      onClick={onClick}
      noBorder
      noShadow
      style={transparent}
    />
  );
}

const transparent: React.CSSProperties = {
  backgroundColor: 'transparent',
};

export { transparent, labelSelector, iconSelector, disabledSelector, keySelector };
