// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
  ActiveElement,
  Chart,
  ChartEvent,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Scatter } from 'react-chartjs-2';
import styled from 'styled-components';
import { Atoms, COLORS } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setActiveMd5,
  setHoveredMd5s,
  setZoomLevel,
  updateIndex,
  ZoomLevel,
} from 'store/reducers/projectEvaluationSlice';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import DebounceSet from 'components/common/DebounceSet';
import useModelEvaluationApi from 'hooks/useModelEvaluationApi';
import { Coordinate, getChartObject, getDefaultDataSet, getDefaultOption, OptionType } from './defaults';
import { SetBoxPlotOptions, setHighlightedData } from './helpers';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import Backdrop from 'components/treactui-template/organisms/backdrop/Backdrop';
import { useParams } from 'react-router-dom';
import useProjectsMetadata from 'hooks/useProjectsMetadata';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  zoomPlugin
);

export type Props = {
  projectId: string;
  modelId: ProjectVersion;
  evaluationId?: string;
};

export default function ModeLBoxPlot({ projectId, modelId, evaluationId }: Readonly<Props>) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { selectedProject } = useProjectsMetadata(id);

  const hoveredMd5s = useAppSelector(s => s.evaluation.hoveredMd5s);
  const zoomLevel = useAppSelector(s => s.evaluation.zoomLevel);
  const index = useAppSelector(s => s.evaluation.index);
  const dispatch = useAppDispatch();

  const ref = useRef<ChartJSOrUndefined<'scatter', Coordinate[], string>>(null);

  const { evaluation, boxPlot, progress } = useModelEvaluationApi(projectId, modelId, evaluationId);
  const activeMd5 = useAppSelector(s => s.evaluation.activeMd5);
  const [hoveredMd5sToShow, setHoveredMd5sToShow] = useState<string[]>([]);
  DebounceSet(hoveredMd5s, setHoveredMd5sToShow);

  const renderLabel = useCallback(
    (i: TooltipItem<'scatter'>) => {
      return `${t('project.label.training.chart.iou')}: ${(i.raw as Coordinate).y?.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}`;
    },
    [i18n.language, t]
  );

  const onHover = useCallback(
    (_, elements: ActiveElement[]) =>
      dispatch(
        setHoveredMd5s(
          elements
            .map(e => {
              if (e.index < 0 || e.index >= evaluation.length) return '';
              return evaluation[e.index].imageMd5;
            })
            .filter(e => e)
        )
      ),
    [dispatch, evaluation]
  );

  const onZoom = useCallback(
    (context: { chart: Chart }) => {
      dispatch(setZoomLevel({ min: context.chart.scales.y.min, max: context.chart.scales.y.max }));
    },
    [dispatch]
  );

  const onClick = useCallback(
    /* eslint-disable @typescript-eslint/no-unused-vars */
    (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      if (elements.length < 1) return;
      const closest = elements[0];
      dispatch(updateIndex(closest.index));
      if (!evaluation[closest.index]) return;

      dispatch(setActiveMd5(evaluation[closest.index].imageMd5));
    },
    [dispatch, evaluation]
  );

  //Options to render the chart
  const options: OptionType = useMemo(() => {
    const _options = getDefaultOption(selectedProject?.projectUseCase?.modelClasses?.length ?? 1);
    _options.onHover = onHover;
    _options.onClick = onClick;
    if (_options.plugins?.tooltip?.callbacks) _options.plugins.tooltip.callbacks.label = renderLabel;
    if (_options.plugins?.zoom?.zoom) _options.plugins.zoom.zoom.onZoom = onZoom;
    if (_options.plugins?.zoom?.pan) _options.plugins.zoom.pan.onPan = onZoom;
    if (_options.scales?.y?.title) _options.scales.y.title.text = t('project.label.training.chart.iou');
    if (_options.scales?.y?.ticks)
      _options.scales.y.ticks.callback = tickValue => tickValue.toLocaleString(i18n.language);

    return _options;
  }, [i18n.language, onZoom, renderLabel, t, onClick, onHover, selectedProject?.projectUseCase?.modelClasses?.length]);

  const data = useMemo(() => {
    const _data = getChartObject();
    const modelClasses = selectedProject?.projectUseCase?.modelClasses;
    if (modelClasses && boxPlot) {
      for (const modelClass of modelClasses) {
        _data.datasets.push(getDefaultDataSet(modelClass));
      }
      evaluation.forEach(e => {
        e.value.forEach((value, index) => {
          _data.datasets[index].data.push({ x: 0 + index / 2, y: value });
        });
      });

      SetBoxPlotOptions(boxPlot, options, modelClasses);
      const evaluationIdx = evaluation.map((e, idx) => ({ e, idx }));
      const hoveredIdxs = evaluationIdx.filter(e => hoveredMd5sToShow.includes(e.e.imageMd5)).map(e => e.idx);

      const activeIdx = evaluationIdx.find(e => e.e.imageMd5 === activeMd5)?.idx ?? undefined;

      setHighlightedData(_data, hoveredIdxs, modelClasses, activeIdx);
    }
    return _data;
  }, [selectedProject?.projectUseCase?.modelClasses, evaluation, boxPlot, options, hoveredMd5sToShow, activeMd5]);
  useEffect(() => {
    //The images of the other charts might be in different order, find the correct idx.
    const evaluationIdx = evaluation.map((e, idx) => ({ e, idx }));
    const hoveredIdxs = evaluationIdx.filter(e => hoveredMd5sToShow.includes(e.e.imageMd5)).map(e => e.idx);

    const activeIdx = evaluationIdx.find(e => e.e.imageMd5 === activeMd5)?.idx ?? undefined;

    setHighlightedData(data, hoveredIdxs, selectedProject?.projectUseCase?.modelClasses, activeIdx);
    ref.current?.update();
  }, [data, hoveredMd5sToShow, activeMd5, evaluation, selectedProject?.projectUseCase?.modelClasses]);

  const [zoomLevelToShow, setZoomLevelToShow] = useState<ZoomLevel>();
  DebounceSet(zoomLevel, setZoomLevelToShow);
  useEffect(() => {
    if (ref.current?.options.scales?.y === undefined) return;
    ref.current.options.scales.y.max = zoomLevelToShow?.max ?? 1;
    ref.current.options.scales.y.min = zoomLevelToShow?.min ?? 0;
    ref.current.update();
  }, [zoomLevelToShow]);

  useEffect(() => {
    if (index < 0 || index > evaluation.length) return;

    const current = evaluation[index]?.imageMd5;
    if (current && current === activeMd5) return;

    dispatch(setActiveMd5(current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, dispatch]);

  return (
    <Container className='chart-container'>
      <Scatter className='chart' options={options} data={data} ref={ref} />
      <Backdrop showBackdrop={progress < 1} width={75}>
        <Atoms.ProgressBar percentage={progress * 100} width={100} />
      </Backdrop>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  height: calc(100% - 2.5rem);
  width: 100%;
  .chart {
    background-color: #f3f5f7;
    border: 1px solid ${COLORS.trgrey5.hex};
  }
`;
