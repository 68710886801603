// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { hideLicenseHeader } from 'store/reducers/licenseSlice';
import InfoBanner, { InfoBannerType } from './InfoBanner';
import { AppPermission } from 'model/AppPermission';
import useContract from 'hooks/useContract';
import styled from 'styled-components';

interface Props {
  appPermission: AppPermission;
}

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const TEN_DAYS = MS_PER_DAY * 10;
const yearZero = new Date(0);

export default function LicenseInfo({ appPermission }: Readonly<Props>) {
  const { loading, contract, hide } = useContract(appPermission);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  if (loading) return <></>;

  const { lastActiveEndDate, isActive, lastStartDate } = contract ?? {
    freeTrainingSessions: 0,
    isActive: false,
    lastActiveEndDate: yearZero,
    lastStartDate: yearZero,
  };
  const now = new Date().getTime();

  const expiration = lastActiveEndDate.getTime() - now;
  const startDateOffset = lastStartDate.getTime() - now;
  const appPermissionString =
    appPermission === AppPermission.CreateCDProject ? t('projectType.featureDetection') : t('projectType.qualityCheck');
  if ((isActive && (expiration > TEN_DAYS || lastStartDate >= lastActiveEndDate)) || hide) return <></>;

  const { title, description, type } = getLicenseTextAndType(isActive, expiration, startDateOffset, lastActiveEndDate);

  return (
    <Root>
      <InfoBanner
        type={type}
        title={t(title, {
          type: appPermissionString,
          days: isActive ? Math.floor(expiration / MS_PER_DAY) : Math.floor(startDateOffset / MS_PER_DAY),
        })}
        description={t(description)}
        onClose={isActive ? () => dispatch(hideLicenseHeader(appPermission)) : undefined}
      />
    </Root>
  );
}

function getLicenseTextAndType(
  isActive: boolean,
  expiration: number,
  startDateOffset: number,
  lastActiveEndDate: Date
) {
  let title = '';
  let description = '';
  let type = InfoBannerType.warning;

  if (isActive && expiration < MS_PER_DAY) {
    title = 'license.warning.today';
    description = 'license.warning.details';
  } else if (isActive && expiration < MS_PER_DAY * 2) {
    title = 'license.warning.tomorrow';
    description = 'license.warning.details';
  } else if (isActive) {
    title = 'license.warning.inDays';
    description = 'license.warning.details';
  } else if (!isActive && startDateOffset > 0 && startDateOffset < MS_PER_DAY) {
    title = 'license.upcoming.today';
    description = 'license.upcoming.details';
  } else if (!isActive && startDateOffset > 0 && startDateOffset < MS_PER_DAY * 2) {
    title = 'license.upcoming.tomorrow';
    description = 'license.upcoming.details';
  } else if (!isActive && startDateOffset > 0) {
    title = 'license.upcoming.inDays';
    description = 'license.upcoming.details';
  } else if (!isActive) {
    type = InfoBannerType.error;
    const expired = lastActiveEndDate > yearZero;
    title = expired ? 'license.expired' : 'license.noLicense';
    description = 'license.details';
  }
  return { title, description, type };
}

const Root = styled.div`
  padding-bottom: 0.65rem;
`;
