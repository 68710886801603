// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SSimContext, LabelOrderResult, SSimContextType } from './SSimContext';
import { useAppSelector } from 'store/hooks';
import { ImageDataType, SSimResult } from './SSimTypes';
import { ImagesContext } from 'components/project/images/ImagesContext';

enum ResponseTypes {
  Progress = 'progress',
  Result = 'result',
}

export default function SSimProvider({ children }: Readonly<React.PropsWithChildren>) {
  const [calculationStatus, setCalculationStatus] = useState({ isCalculating: false, input: [] as ImageDataType[] });
  const [sortedSimilarity, setSortedSimilarity] = useState<LabelOrderResult[]>([]);
  const [result, setResult] = useState<LabelOrderResult[]>([]);
  const [progress, setProgress] = useState(0);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    if (result.length < 1) {
      // Initialized data
      setResult(sortedSimilarity);
    } else if (sortedSimilarity.some(s => result.find(r => r.md5 === s.md5)?.labelOrder !== s.labelOrder)) {
      setUpdateAvailable(true);
    }
  }, [sortedSimilarity, result]);

  const refreshResult = useCallback(() => {
    setResult(sortedSimilarity);
    setUpdateAvailable(false);
  }, [sortedSimilarity]);

  const { images, loading } = useContext(ImagesContext);
  const imageAndMaskData = images.map(l => `${l.imageMd5}:${l.hasMask}`).join(',');

  const worker = useRef<Worker>();

  useEffect(() => {
    worker.current = new Worker(new URL('./StructuredSimilarity.worker.ts', import.meta.url));

    worker.current.onmessage = e => {
      switch (e.data.type) {
        case ResponseTypes.Progress:
          if (e.data.count && e.data.numMask > 0) setProgress(e.data.count / e.data.numMask);
          break;
        case ResponseTypes.Result:
          e.data.result.sort((a: SSimResult, b: SSimResult) => a.similarity - b.similarity);
          setSortedSimilarity(
            e.data.result
              .filter((r: SSimResult) => r.md5)
              .map((r: SSimResult, i: number) => ({ md5: r.md5 ?? '', labelOrder: i + 1 }))
          );
          setCalculationStatus(s => ({ isCalculating: false, input: s.input }));
          break;
      }
    };

    return () => {
      worker.current?.terminate();
      worker.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    if (calculationStatus.isCalculating) {
      console.info('Skipping update, evaluation in progress');
      return;
    }
    if (!window.Worker || !worker.current) return;
    const input = images.map(
      s =>
        ({
          imageMd5: s.imageMd5 ?? '',
          hasMask: s.hasMask ?? '',
          previewData: s.previewData?.map(p => p) ?? [],
        }) as ImageDataType
    );
    if (!calculationStatus.isCalculating && sortAndStringify(input) === sortAndStringify(calculationStatus.input)) {
      console.info('Content did not change, skipping update');
      return;
    }
    setCalculationStatus({ isCalculating: true, input });
    worker.current.postMessage(input);

    // We only want to update the similarity if the image or mask data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageAndMaskData, loading, calculationStatus]);

  const automate = useAppSelector(s => s.label.automaticLabelOrderUpdate);
  const interval = useAppSelector(s => s.label.labelOrderUpdateInterval);

  useEffect(() => {
    if (!automate) return;
    const intervalId = setInterval(() => {
      refreshResult();
    }, interval * 1000);
    return () => clearInterval(intervalId);
  }, [automate, interval, refreshResult]);

  const contextValue: SSimContextType = useMemo(
    () => ({ isCalculating: calculationStatus.isCalculating, result, progress, refreshResult, updateAvailable }),
    [calculationStatus, result, progress, refreshResult, updateAvailable]
  );

  return <SSimContext.Provider value={contextValue}>{children}</SSimContext.Provider>;
}

const sortAndStringify = (input: ImageDataType[]) => {
  return JSON.stringify(input.toSorted((a, b) => (a.imageMd5 >= b.imageMd5 ? -1 : 1)));
};
