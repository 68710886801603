// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconDrawSquare = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-draw-square'}
      data-name='svg-draw-square'
      data-testid='svg-draw-square'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path d='M1 1H4V4H1V1Z' fill={fill} />
      <path d='M32 1H35V4H32V1Z' fill={fill} />
      <path d='M32 32H35V35H32V32Z' fill={fill} />
      <path d='M1 32H4V35H1V32Z' fill={fill} />
      <path d='M1 21H4V27H1V21Z' fill={fill} />
      <path d='M1 9H4V15H1V9Z' fill={fill} />
      <path d='M15 1V4H9V1H15Z' fill={fill} />
      <path d='M27 1V4H21V1H27Z' fill={fill} />
      <path d='M32 21H35V27H32V21Z' fill={fill} />
      <path d='M32 9H35V15H32V9Z' fill={fill} />
      <path d='M15.0309 32V35H9.03088V32H15.0309Z' fill={fill} />
      <path d='M27.0309 32V35H21.0309V32H27.0309Z' fill={fill} />
    </svg>
  );
};

export default IconDrawSquare;
