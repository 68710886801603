// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconPolygon = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-polygon'}
      data-name='svg-polygon'
      data-testid='svg-polygon'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        d='M17.9569 6C16.5762 6 15.4569 4.88071 15.4569 3.5C15.4569 2.11929 16.5762 1 17.9569 1C19.3376 1 20.4569 2.11929 20.4569 3.5C20.4569 4.88071 19.3376 6 17.9569 6Z'
        fill={fill}
      />
      <path
        d='M8.53711 34.9628C7.1564 34.9628 6.03711 33.8436 6.03711 32.4628C6.03711 31.0821 7.1564 29.9628 8.53711 29.9628C9.91782 29.9628 11.0371 31.0821 11.0371 32.4628C11.0371 33.8436 9.91782 34.9628 8.53711 34.9628Z'
        fill={fill}
      />
      <path
        d='M30.3616 14.4126C30.3616 15.7933 31.4809 16.9126 32.8616 16.9126C34.2423 16.9126 35.3616 15.7933 35.3616 14.4126C35.3616 13.0319 34.2423 11.9126 32.8616 11.9126C31.4809 11.9126 30.3616 13.0319 30.3616 14.4126Z'
        fill={fill}
      />
      <path
        d='M27.3306 35.2273C25.9499 35.2273 24.8306 34.108 24.8306 32.7273C24.8306 31.3466 25.9499 30.2273 27.3306 30.2273C28.7113 30.2273 29.8306 31.3466 29.8306 32.7273C29.8306 34.108 28.7113 35.2273 27.3306 35.2273Z'
        fill={fill}
      />
      <path d='M11.5373 6.00228L13.3006 8.42933L9.02088 11.5388L7.25752 9.11171L11.5373 6.00228Z' fill={fill} />
      <path d='M3.28997 21.3226L6.14314 20.3955L7.77786 25.4267L4.92469 26.3537L3.28997 21.3226Z' fill={fill} />
      <path d='M30.9891 26.3537L28.136 25.4267L29.7707 20.3955L32.6238 21.3226L30.9891 26.3537Z' fill={fill} />
      <path d='M28.6563 9.11171L24.3765 6.00228L22.6132 8.42933L26.8929 11.5388L28.6563 9.11171Z' fill={fill} />
      <path d='M20.6019 33.9005V30.9005H15.3119V33.9005H20.6019Z' fill={fill} />
      <path
        d='M3 17.0951C1.61929 17.0951 0.5 15.9758 0.5 14.5951C0.5 13.2144 1.61929 12.0951 3 12.0951C4.38071 12.0951 5.5 13.2144 5.5 14.5951C5.5 15.9758 4.38071 17.0951 3 17.0951Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconPolygon;
