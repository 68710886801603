// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditImageProps, ImagesContext, ImagesContextType } from './ImagesContext';
import { HubContext } from 'components/common/emitter/HubContext';
import {
  GetAllLabelPackageInfoAckMessage,
  LabelPackageInfoMessage,
} from '@tls/sw91-communication/types/com.api_db_access';
import { useParams } from 'react-router-dom';
import ParamsWithId from 'model/ParamsWithId';
import { get } from '@tls/react-saf-ui';
import { useQuery } from '@tanstack/react-query';
import { GenericResponse } from 'model/reponse/GenericResponse';
import { GetAllLabelPackageInfoAckMessages } from 'model/reponse/GetAllLabelPackageInfoAckMessages';

const Edit = (images: LabelPackageInfoMessage[], image: EditImageProps) => {
  const idx = images.findIndex(i => i?.imageMd5 === image?.imageMd5);
  if (idx < 0) return images;

  const newImages = [...images];
  newImages[idx] = { ...newImages[idx], ...image };
  return newImages;
};

const Add = (images: LabelPackageInfoMessage[], image: LabelPackageInfoMessage) => {
  const idx = images.findIndex(i => i?.imageMd5 === image?.imageMd5);
  if (idx > -1) return Edit(images, image);

  return [...images, image];
};

export default function ImagesProvider({ children }: Readonly<React.PropsWithChildren>) {
  const [images, setImages] = useState<LabelPackageInfoMessage[]>([]);
  const [imagesIdsToHave, setImagesIdsToHave] = useState<Set<string>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams<ParamsWithId>();

  useEffect(() => {
    setImages([]);
  }, [id]);

  const { data, refetch, isFetched } = useQuery<GenericResponse<GetAllLabelPackageInfoAckMessages>>({
    queryKey: [`projects/${id}/labels`],
    queryFn: () => get(`projects/${id}/labels`),
  });

  useEffect(() => {
    if (!isFetched || !data?.data?.statusPerImage) return;
    if (data.data.statusPerImage && Array.isArray(data.data.statusPerImage)) {
      const imageMd5s = data.data.statusPerImage.map(s => s.imageMd5);
      setImagesIdsToHave(new Set(imageMd5s));
      setImages(imgs => imgs.filter(i => imageMd5s.includes(i?.imageMd5 ?? '')));
    }
  }, [data, isFetched]);

  useEffect(() => {
    if (imagesIdsToHave && imagesIdsToHave.size <= images.length) {
      setIsLoading(false);
    }
  }, [imagesIdsToHave, images?.length]);

  const editImage = useCallback((props: EditImageProps) => {
    setImages(imgs => Edit(imgs, props));
  }, []);

  const addImage = useCallback(
    (image: LabelPackageInfoMessage) => {
      if (image.imageMd5) {
        setImagesIdsToHave(md5s => new Set(...(md5s ?? []), image.imageMd5));
      }
      setImages(imgs => Add(imgs, image));
    },
    [setImages]
  );

  const removeImage = useCallback(
    (imageMd5: string) => {
      setImagesIdsToHave(md5s => new Set([...(md5s ?? [])].filter(i => i !== imageMd5)));
      setImages(imgs => imgs.filter(i => i?.imageMd5 !== imageMd5));
    },
    [setImages]
  );

  const listener = useCallback(
    (message: GetAllLabelPackageInfoAckMessage[]) => {
      for (const messageItem of message) {
        if (messageItem.messageHeader?.projectId !== id || !messageItem.labelPackageInfo) return;
        setImages(imgs => Add(imgs, messageItem.labelPackageInfo!));
      }
    },
    [id]
  );

  const { events } = useContext(HubContext);

  useEffect(() => {
    events.on('onLabelPackageInfo', listener);

    return () => {
      events.off('onLabelPackageInfo', listener);
    };
  }, [events, listener]);

  const contextValue: ImagesContextType = useMemo(
    () => ({
      images,
      addImage,
      removeImage,
      refetch,
      loading: isLoading,
      editImage,
    }),
    [images, addImage, removeImage, refetch, editImage, isLoading]
  );

  return <ImagesContext.Provider value={contextValue}>{children}</ImagesContext.Provider>;
}
