// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconErase = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-erase'}
      data-name='svg-erase'
      data-testid='svg-erase'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.50172 14.5108L3.1394 19.0103L12.1384 29.7349L13.0669 30.8708L17.0077 30.8707L20.0719 28.2996L8.50172 14.5108ZM10.7998 12.5824L22.37 26.3712L33.8607 16.7294L22.2905 2.94062L10.7998 12.5824Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconErase;
