// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useSafContext } from '@tls/react-saf-ui';
import { AppPermission } from 'model/AppPermission';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useProjectsMetadata from './useProjectsMetadata';
import ParamsWithId from 'model/ParamsWithId';
import { UseCase } from '@tls/sw91-communication/types/com.base';

export default function useAppPermissions() {
  const { currentUser } = useSafContext();
  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);

  const hasUserPermission = useCallback(
    (permission: AppPermission) =>
      currentUser?.appPermissions?.easymodel ? currentUser.appPermissions.easymodel.includes(permission) : false,
    [currentUser?.appPermissions]
  );

  const hasPermissionForProject = useMemo(() => {
    return (
      (selectedProject?.projectUseCase?.useCase === UseCase.QUALITY_CHECK &&
        hasUserPermission(AppPermission.CreateQCProject)) ||
      (selectedProject?.projectUseCase?.useCase === UseCase.COMPONENT_DETECTION &&
        hasUserPermission(AppPermission.CreateCDProject))
    );
  }, [selectedProject?.projectUseCase?.useCase, hasUserPermission]);

  const hasCreateCDProjectPermission = hasUserPermission(AppPermission.CreateCDProject);
  const hasCreateQCProjectPermission = hasUserPermission(AppPermission.CreateQCProject);
  const hasSAMSegmentPermission = hasUserPermission(AppPermission.SAMSegment);
  const hasMultiLabelCDPermision = hasUserPermission(AppPermission.MultiLabelCD);

  return {
    hasCreateCDProjectPermission,
    hasCreateQCProjectPermission,
    hasSAMSegmentPermission,
    hasMultiLabelCDPermision,
    hasPermissionForProject,
  };
}
