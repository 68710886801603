// SPDX-License-Identifier: LicenseRef-TRUMPF
// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tag } from 'components/projectSelection/common/ProjectSettingTypes';

export function useProjectTagManagement(initialTags: string[], allTags: string[]) {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [selectedTag, setSelectedTag] = useState<Tag>({ label: '' });
  const [allTagsAsObject, setAllTagsAsObject] = useState<Tag[]>([]);

  const newTags = useMemo(() => {
    const tagsObject = allTags.map(t => ({ label: t }));
    if (selectedTag.label && !allTags.includes(selectedTag.label)) {
      tagsObject.push(selectedTag);
    }
    return tagsObject;
  }, [allTags, selectedTag]);

  useEffect(() => {
    setAllTagsAsObject(newTags);
  }, [newTags]);

  const addSelectedTag = useCallback(() => {
    const trimmedLabel = selectedTag.label?.trim();
    if (trimmedLabel && !tags.includes(trimmedLabel)) {
      setTags(prevTags => [...prevTags, trimmedLabel]);
    }
  }, [selectedTag.label, tags]);

  const removeTag = useCallback((tag: string) => {
    setTags(prevTags => prevTags.filter(t => t !== tag));
  }, []);

  return { tags, setTags, selectedTag, allTagsAsObject, setSelectedTag, addSelectedTag, removeTag };
}
