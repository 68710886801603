// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { createGraphData, createGraphDataSet, getDefaultOptions, updateDataPoints } from './defaults';
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useParams } from 'react-router-dom';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import ParamsWithId from 'model/ParamsWithId';
import { getColorOption } from 'components/common/ColorOptions';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, zoomPlugin);

export type Props = {
  history: HistoryInformationMessage[];
};

export default function TrainingChart({ history }: Readonly<Props>) {
  const { t, i18n } = useTranslation();

  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);

  const ref = useRef<ChartJSOrUndefined<'line', number[], number>>(null);

  const renderLabel = useCallback(
    (i: TooltipItem<'line'>) =>
      `${t('project.label.training.chart.iou')}: ${(i.raw as number)?.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}`,
    [i18n.language, t]
  );

  const renderFooter = useCallback(
    (i: TooltipItem<'line'>[]): string => {
      if (
        i.some(d => {
          return d.dataset.pointStyle && d.dataset.pointStyle[d.dataIndex] === 'rect';
        })
      ) {
        return t('project.label.training.chart.modelUpdated');
      }
      return '';
    },
    [t]
  );

  const options = useMemo(() => {
    const _options = getDefaultOptions();
    if (_options.plugins?.tooltip?.callbacks) _options.plugins.tooltip.callbacks.footer = renderFooter;
    if (_options.scales?.y?.ticks)
      _options.scales.y.ticks.callback = tickValue => tickValue.toLocaleString(i18n.language);
    if (_options.scales?.x?.title) _options.scales.x.title.text = t('project.label.training.chart.epochs');
    if (_options.scales?.y?.title) _options.scales.y.title.text = t('project.label.training.chart.iou');
    if (_options.plugins?.tooltip?.callbacks) _options.plugins.tooltip.callbacks.label = renderLabel;

    return _options;
  }, [i18n.language, renderFooter, renderLabel, t]);

  const data = useMemo(() => {
    const _data = createGraphData();
    selectedProject?.projectUseCase?.modelClasses?.forEach(modelClass => {
      _data.datasets.push(
        createGraphDataSet(modelClass.className ?? '', `rgb(${getColorOption(modelClass?.labelColor).id})`)
      );
    });
    updateDataPoints(history, _data);

    return _data;
  }, [history, selectedProject?.projectUseCase?.modelClasses]);

  useEffect(() => {
    ref.current?.update();
  }, [history]);

  return (
    <Container className='chart-container'>
      <Line options={options} data={data} height={100} ref={ref} />
      <Legend>
        <Rect />
        {t('project.label.training.chart.modelUpdated')}
      </Legend>
    </Container>
  );
}

const Container = styled.div`
  min-height: 300px;
  height: 300px;
  width: 100%;
`;

const Rect = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  background-color: white;
  border: 1px solid black;
`;
const Legend = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;
