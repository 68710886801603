// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconDrawCircle = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-draw-circle'}
      data-name='svg-draw-circle'
      data-testid='svg-draw-circle'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        d='M32.1513 8.55403L29.6741 10.2435C29.6741 10.2435 28.7452 8.93108 27.9171 8.10238C27.0889 7.27368 25.788 6.35495 25.788 6.35495L27.4716 3.85092C28.0035 4.17888 29.3381 5.24885 30.0548 5.96604C30.7716 6.68324 31.6599 7.79152 32.1513 8.55403Z'
        fill={fill}
      />
      <path
        d='M21.3126 1.32422L20.7565 4.27073C20.7565 4.27073 19.1716 4 18 4C16.8284 4 15.259 4.27073 15.259 4.27073L14.6779 1.30983C15.2858 1.16547 16.9861 0.977764 18 0.977764C19.0139 0.977764 20.4258 1.13281 21.3126 1.32422Z'
        fill={fill}
      />
      <path
        d='M14.6827 34.6535L15.2387 31.707C15.2387 31.707 16.8237 31.9778 17.9952 31.9778C19.1668 31.9778 20.7362 31.707 20.7362 31.707L21.3173 34.6679C20.7094 34.8123 19.0092 35 17.9952 35C16.9813 35 15.5694 34.845 14.6827 34.6535Z'
        fill={fill}
      />
      <path
        d='M34.6534 21.3173L31.7069 20.7613C31.7069 20.7613 31.9776 19.1763 31.9776 18.0048C31.9776 16.8332 31.7069 15.2638 31.7069 15.2638L34.6678 14.6827C34.8121 15.2906 34.9998 16.9908 34.9998 18.0048C34.9998 19.0187 34.8448 20.4306 34.6534 21.3173Z'
        fill={fill}
      />
      <path
        d='M27.4475 32.1423L25.7581 29.6651C25.7581 29.6651 27.0705 28.7362 27.8992 27.9081C28.728 27.0799 29.6467 25.7791 29.6467 25.7791L32.1507 27.4627C31.8227 27.9946 30.7527 29.3292 30.0355 30.0459C29.3183 30.7626 28.21 31.6509 27.4475 32.1423Z'
        fill={fill}
      />
      <path
        d='M3.87447 27.436L6.35115 25.7457C6.35115 25.7457 7.28044 27.0578 8.10887 27.8862C8.93729 28.7147 10.2385 29.633 10.2385 29.633L8.55571 32.1376C8.02376 31.8098 6.68878 30.7402 5.97182 30.0233C5.25485 29.3063 4.36613 28.1983 3.87447 27.436Z'
        fill={fill}
      />
      <path
        d='M1.34645 14.6827L4.29296 15.2387C4.29296 15.2387 4.02224 16.8237 4.02224 17.9952C4.02224 19.1668 4.29296 20.7362 4.29296 20.7362L1.33206 21.3173C1.1877 20.7094 1 19.0092 1 17.9952C1 16.9813 1.15505 15.5694 1.34645 14.6827Z'
        fill={fill}
      />
      <path
        d='M8.57036 3.87347L10.2607 6.35015C10.2607 6.35015 8.94852 7.27944 8.12009 8.10787C7.29167 8.93629 6.37336 10.2375 6.37336 10.2375L3.86878 8.55471C4.19657 8.02276 5.2661 6.68778 5.98306 5.97082C6.70002 5.25385 7.80801 4.36513 8.57036 3.87347Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconDrawCircle;
