// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconFilterSort = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-filter'}
      data-name='svg-icon-filter'
      data-testid='svg-icon-filter'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.5rem'}
      height={height ?? '2.55rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 14.5V26.5L15.5 23.5V14.5L26 4H2L12.5 14.5Z'
        fill={fill}
        stroke={stroke}
      />
      <path
        d='M20.502 20.9032L19 19.4012L27.4012 11L35.8023 19.4012L34.3003 20.9032L27.4012 14.0041L20.502 20.9032Z'
        fill={fill}
      />
      <path
        d='M20.6798 24.1168L19.1777 25.6189L27.5789 34.02L35.9801 25.6188L34.478 24.1168L27.5789 31.016L20.6798 24.1168Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconFilterSort;
