// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconPencil = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-pencil'}
      data-name='svg-pencil'
      data-testid='svg-pencil'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        d='M29 2.49994L32 4.99994L17.0159 26.2665C17.0235 26.1279 17.0273 25.9875 17.0273 25.8451C17.0273 23.5861 14.7398 21.7544 12.0023 21.7544C11.5631 21.7544 11.1714 21.8312 10.8233 21.9694L29 2.49994Z'
        fill={fill}
      />
      <path
        d='M7.3231 28.2946C7.3231 26.0661 8.2 23 10.975 23C13.7125 23 16 24.8317 16 27.0907C16 31.6088 12.1466 34.218 8.92721 34.8891C7.1731 35.1933 2.4625 34.8447 1 34.1731C1.12596 34.1134 1.25263 34.0536 1.37974 33.9936C4.24219 32.6425 7.3231 31.1883 7.3231 28.2946Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconPencil;
