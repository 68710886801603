// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconFilterReset = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-filter-reset'}
      data-name='svg-icon-filter-reset'
      data-testid='svg-icon-filter-reset'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.9809 6.85574H7.84641V4L1.5 7.80766L7.84641 11.6153V8.75957H13.5579H24.9809C29.1864 8.75957 32.5962 12.1693 32.5962 16.3749C32.5962 20.5804 29.1864 23.9902 24.9809 23.9902H23.077L21.1732 25.894H24.9809C30.2383 25.894 34.5 21.6323 34.5 16.3749C34.5 11.1175 30.2383 6.85574 24.9809 6.85574ZM7.84641 18.2787L14.5098 24.9421V32.5574L16.4136 30.6536V24.9421L23.077 18.2787H7.84641Z'
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};

export default IconFilterReset;
