// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPermission } from 'model/AppPermission';
import { ContractOverview } from 'model/ContractOverview';

export interface LicenseState {
  contract?: ContractOverview;
  loading: boolean;
  hide: Record<AppPermission, boolean>;
}

const initHide = {
  CreateCDProject: false,
  CreateQCProject: false,
  SAMSegment: false,
  MultiLabelCD: false,
};

const initialState: LicenseState = {
  contract: undefined,
  loading: true,
  hide: initHide,
};

export const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<ContractOverview>) => {
      state.loading = false;
      state.contract = action.payload;
      state.hide = initHide;
    },
    hideHeader: (state, action: PayloadAction<AppPermission>) => {
      state.hide[action.payload] = true;
    },
  },
});

export const { setContract: setLicenseContract, hideHeader: hideLicenseHeader } = licenseSlice.actions;

export default licenseSlice.reducer;
