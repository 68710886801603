// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import { COLORS } from '@tls/treact-ui';
import {
  ChartData,
  ChartDataset,
  Color,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
export type DataChartType = ChartData<'line', number[], number>;

export function createGraphData(): DataChartType {
  return {
    labels: [],
    datasets: [],
  };
}
export function createGraphDataSet(label: string, color: Color): ChartDataset<'line', number[]> {
  return {
    label: label,
    type: 'line' as const,
    data: [],
    fill: false,
    pointStyle: [],
    borderColor: color,
    backgroundColor: color,
    pointBackgroundColor: COLORS.trwhite.hex,
    pointHoverRadius: [],
    hoverBorderWidth: 2,
    hoverBackgroundColor: COLORS.trwhite.hex,
    pointRadius: [],
    borderWidth: 0.7,
    pointBorderWidth: 1.2,
    pointHitRadius: 10,
  };
}

export type OptionsType = _DeepPartialObject<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
>;

export function getDefaultOptions(): OptionsType {
  return {
    responsive: true,
    clip: false,
    font: {
      family: 'Open Sans, sans-serif',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        animation: false,
        backgroundColor: COLORS.trwhite.hex,
        titleColor: COLORS.trblack.hex,
        bodyColor: COLORS.trblack.hex,
        borderColor: COLORS.trblack.hex,
        footerColor: COLORS.trblack.hex,
        borderWidth: 0.3,
        boxPadding: 5,
        cornerRadius: 0,
        callbacks: {},
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
        limits: {
          y: {
            max: 1,
            min: 0,
          },
          x: {
            min: 0,
          },
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        grid: {
          display: false,
        },
        ticks: {
          color: COLORS.trblack.hex,
          font: {
            weight: 'bold',
          },
          precision: 0,
        },
        border: {
          color: COLORS.trgrey4.hex,
        },
        title: {
          align: 'end',
          text: 'Epochs',
          display: true,
          font: {
            weight: 'bold',
          },
          color: COLORS.trblack.hex,
        },
      },
      y: {
        grid: {
          display: true,
        },
        border: {
          display: false,
        },
        title: {
          text: 'IoU',
          display: true,
          font: {
            weight: 'bold',
          },
          color: COLORS.trblack.hex,
        },
        ticks: {
          color: COLORS.trblack.hex,
        },
        suggestedMax: 1,
        suggestedMin: 0,
      },
    },
    maintainAspectRatio: false,
  };
}

export function updateDataPoints(history: HistoryInformationMessage[], data: DataChartType) {
  if (data.datasets.length <= 0) return;
  const total = data.datasets[0].data.length;
  history.slice(total).forEach(h => {
    data.labels?.push(h.epochNumber ?? 0);
    for (let i = 0; i < data.datasets.length; i++) {
      const pointStyle = data.datasets[i].pointStyle;
      const pointHoverRadius = data.datasets[i].pointHoverRadius;
      const pointRadius = data.datasets[i].pointRadius;

      if (!Array.isArray(pointHoverRadius) || !Array.isArray(pointRadius) || !Array.isArray(pointStyle)) return;
      const performanceData = JSON.parse(h.performanceData ?? '{}')?.['iou_class_' + (i + 1)] ?? 0;
      data.datasets[i].data.push(performanceData);

      if (h.modelUpdate) {
        pointStyle?.push('rect');
        pointHoverRadius.push(8);
        pointRadius.push(6);
      } else {
        pointStyle.push('circle');
        pointHoverRadius.push(1);
        pointRadius.push(0);
      }
    }
  });
}
