// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';

export default function HighPrecisionImage({ width, height, id, viewBox, ...other }: Readonly<IconProps>) {
  return (
    <svg
      id={id ?? 'svg-icon-high-precision'}
      width={width ?? 120}
      height={height ?? 96}
      viewBox={viewBox ?? '0 0 120 96'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <rect width='120' height='96' fill='url(#paint0_linear_1187_35815)' />
      <rect x='16.5' y='17.5' width='88' height='61' fill='#E6E6E6' stroke='#A1A1A1' />
      <path d='M53.4899 51.8491H39.8486V62.459H53.4899V51.8491Z' fill='#285172' />
      <path d='M98.4899 62.8491H84.8486V73.459H98.4899V62.8491Z' fill='#285172' />
      <path d='M83.4899 42.8491H69.8486V53.459H83.4899V42.8491Z' fill='#285172' />
      <path d='M38.4899 32.8491H24.8486V43.459H38.4899V32.8491Z' fill='#285172' />
      <path d='M68.4909 22.8491H54.8496V33.459H68.4909V22.8491Z' fill='#285172' />
      <defs>
        <linearGradient
          id='paint0_linear_1187_35815'
          x1='108.402'
          y1='20.83'
          x2='73.546'
          y2='85.9686'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0.05' />
          <stop offset='1' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
