// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconMagnifyingGlassMinus = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-magnifying-glass-minus'}
      data-name='svg-magnifying-glass-minus'
      data-testid='svg-magnifying-glass-minus'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 24 24'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path d='M14.8785 11.518H6.37855V10.018H14.8785V11.518Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4505 10.7336C18.4505 12.3616 17.9475 13.9146 16.9935 15.2391L21.497 19.7371L19.7305 21.5001L15.23 17.0046C13.9065 17.9611 12.354 18.4646 10.725 18.4646C6.4655 18.4646 3 14.9966 3 10.7336C3 6.47062 6.4655 3.00262 10.725 3.00262C14.985 3.00262 18.4505 6.47062 18.4505 10.7336ZM14.5905 6.82212C13.552 5.78362 12.1705 5.21162 10.701 5.21162C7.669 5.21162 5.2015 7.67862 5.201 10.7111C5.201 13.7436 7.6685 16.2111 10.701 16.2111C13.7335 16.2111 16.201 13.7436 16.201 10.7111C16.201 9.24212 15.6295 7.86112 14.5905 6.82212Z'
      />
    </svg>
  );
};

export default IconMagnifyingGlassMinus;
