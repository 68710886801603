// SPDX-License-Identifier: LicenseRef-TRUMPF
// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//

import React from 'react';
import { Atoms, ICONS, Molecules } from '@tls/treact-ui';
import styled from 'styled-components';
import { UseCase } from '@tls/sw91-communication/types/com.base';

const { IconPlus } = ICONS;

export const TagInput = ({
  allTagsAsObject,
  selectedTag,
  setSelectedTag,
  addSelectedTag,
  removeTag,
  tags,
  containerRef,
  t,
  useCase,
}) => (
  <>
    <Atoms.Divider className='left-divider push-up' headline={t('project.tag.title')} label='' />

    <TwoColumns useCase={useCase}>
      <Molecules.Combobox
        options={allTagsAsObject}
        selectedOption={selectedTag}
        keySelector={tag => tag.label}
        labelSelector={tag => tag.label}
        noResultText=''
        onChange={setSelectedTag}
        containerRef={containerRef}
        allowCustomValue
        className='no-shadow push-right'
        label={t('project.settings.tag.name')}
      />
      <CenterButton>
        <Atoms.Button variant='secondary' icon={<IconPlus />} label='Add Tag' onClick={addSelectedTag} />
      </CenterButton>
    </TwoColumns>
    <TagContainer>
      {tags.map(t => (
        //@ts-expect-error
        <Atoms.Tag key={t} label={t} onClose={() => removeTag(t)} className='push-right' />
      ))}
    </TagContainer>
  </>
);

const TwoColumns = styled.div<{ useCase: UseCase }>`
  display: grid;
  grid-template-columns: ${({ useCase }) =>
    useCase === UseCase.QUALITY_CHECK ? 'repeat(3, 1fr) 38px' : 'repeat(2, 1fr)'};
  gap: 1rem;
`;

const CenterButton = styled.div`
  margin: auto 0;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: -1rem;
`;
