// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, FLAGS, Organisms } from '@tls/treact-ui';
import { Contract } from 'model/Contract';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

const { Table } = Organisms;

const InformationFlag = FLAGS.COMPONENTS.information;

export default function ContractsTable({ data, isLoading, filterText }: Readonly<Props>) {
  const { t } = useTranslation();

  const generateContractRow = useCallback((contract: Contract) => {
    return (
      <Table.Row key={`${contract.contractId}:${contract.customerNumber}:${contract.endCustomerNumber}`}>
        <Table.Cell style={{ minWidth: '35ch' }} width={1} alignment='LEFT'>
          {contract.contractId}
        </Table.Cell>
        <Table.Cell style={{ minWidth: '25ch' }} width={1} alignment='LEFT'>
          {contract.materialNumber}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.customerNumber}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.endCustomerNumber}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.status}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.startDate?.toLocaleDateString()}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.endDate?.toLocaleDateString()}
        </Table.Cell>
        <Table.Cell width={1} alignment='LEFT'>
          {contract.type}
        </Table.Cell>
      </Table.Row>
    );
  }, []);

  const filteredData = useMemo(
    () =>
      data?.filter(
        d =>
          !filterText ||
          d.materialNumber.includes(filterText) ||
          d.contractId.includes(filterText) ||
          d.customerNumber.includes(filterText) ||
          d.endCustomerNumber.includes(filterText) ||
          d.status.includes(filterText.toUpperCase()) ||
          d.startDate.toLocaleDateString().includes(filterText) ||
          d.endDate.toLocaleDateString().includes(filterText) ||
          d.type.includes(filterText)
      ),
    [data, filterText]
  );

  const sortArray = useCallback((array: Contract[], sortProp: string) => sortBy(array, sortProp), []);

  const onSortByContractId = useCallback((array: Contract[]) => sortArray(array, 'contractId'), [sortArray]);
  const onSortByMaterialNumber = useCallback((array: Contract[]) => sortArray(array, 'materialNumber'), [sortArray]);
  const onSortByCustomerNumber = useCallback((array: Contract[]) => sortArray(array, 'customerNumber'), [sortArray]);
  const onSortByEndCustomerNumber = useCallback(
    (array: Contract[]) => sortArray(array, 'endCustomerNumber'),
    [sortArray]
  );
  const onSortByStatus = useCallback((array: Contract[]) => sortArray(array, 'status'), [sortArray]);
  const onSortByStartDate = useCallback((array: Contract[]) => sortArray(array, 'startDate'), [sortArray]);
  const onSortByEndDate = useCallback((array: Contract[]) => sortArray(array, 'endDate'), [sortArray]);
  const onSortByType = useCallback((array: Contract[]) => sortArray(array, 'type'), [sortArray]);

  return (
    <Table list={filteredData ?? []}>
      {(contracts: Contract[]) => (
        <Table.Content>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderSortCell
                style={{ minWidth: '35ch' }}
                width={1}
                sortFn={onSortByContractId}
                sortKey='contractId'
              >
                {t('settings.contracts.contractId')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell
                style={{ minWidth: '25ch' }}
                width={1}
                sortFn={onSortByMaterialNumber}
                sortKey='materialNumber'
              >
                {t('settings.contracts.materialNumber')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByCustomerNumber} sortKey='customernumber'>
                {t('settings.contracts.customerNumber')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByEndCustomerNumber} sortKey='endCustomernumber'>
                {t('settings.contracts.endCustomerNumber')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByStatus} sortKey='status'>
                {t('settings.contracts.status')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByStartDate} sortKey='startDate'>
                {t('settings.contracts.startDate')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByEndDate} sortKey='endDate'>
                {t('settings.contracts.endDate')}
              </Table.HeaderSortCell>
              <Table.HeaderSortCell width={1} sortFn={onSortByType} sortKey='type'>
                {t('settings.contracts.licenseSource')}
              </Table.HeaderSortCell>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body scrollView={<ScrollableContainer />}>
            {isLoading && <Atoms.Loader label='' />}
            {!isLoading && contracts.length < 1 && (
              <Table.Row>
                <Table.Cell width='100%' alignment={'CENTER'}>
                  <NoEntryContainer>
                    <InformationFlag width={'1rem'} />
                    {t('settings.contracts.noContracts')}
                  </NoEntryContainer>
                </Table.Cell>
              </Table.Row>
            )}
            {contracts.map(generateContractRow)}
          </Table.Body>
        </Table.Content>
      )}
    </Table>
  );
}

interface Props {
  data: Contract[] | undefined;
  isLoading: boolean;
  filterText?: string;
}

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const NoEntryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
