// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { UseCase } from '@tls/sw91-communication/types/com.base';
import { Atoms } from '@tls/treact-ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const caseNameMap: { [key: number]: string } = {
  1: 'componentDetection',
  2: 'qualityControl',
};
export const ProjectNameInput = ({ projectName, setProjectName, isEditing, useCase }) => {
  const { t } = useTranslation();
  const [caseName, setCaseName] = React.useState<string>('');

  useEffect(() => {
    const mappedCaseName = caseNameMap[useCase];
    mappedCaseName && setCaseName(mappedCaseName);
  }, [useCase]);
  return (
    <>
      <Atoms.Divider
        headline={isEditing ? t(`project.settings.dialog.${caseName}.title`) : t('projects.create.new')}
        label=''
        className='left-divider'
      />
      <Atoms.InputFieldText
        flag='mandatory'
        label={t('project.name')}
        value={projectName}
        onValueChange={setProjectName}
        className='match-header'
      />

      {useCase === UseCase.QUALITY_CHECK && (
        <Atoms.Divider
          headline={t('projects.create.qualityControl.calibrationSettings')}
          label=''
          className='left-divider'
        />
      )}
    </>
  );
};
