// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import React, { useCallback } from 'react';
import { Organisms } from '@tls/treact-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleImportDialog } from 'store/reducers/projectSettingsSlice';
import ImportProjectDialogContent from 'components/projectSelection/settings/ImportProjectDialogContent';

const { Dialog } = Organisms;

export default function ImportProjectDialog() {
  const { importDialog } = useAppSelector(s => s.projectSettings);
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleImportDialog());
  }, [dispatch]);

  return (
    <Dialog enableClosing open={importDialog} size='large' onClose={onClose}>
      <ImportProjectDialogContent saveOnEnter={importDialog} />
    </Dialog>
  );
}
