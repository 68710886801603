// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconIntelligentScissor = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-intelligent-scissor'}
      data-name='svg-intelligent-scissor'
      data-testid='svg-intelligent-scissor'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path d='M2.5 4.5H27.5V7.5H5.5V31.5H2.5V4.5Z' fill={fill} />
      <path
        d='M31.1508 14.2368C29.77 14.2368 28.6508 13.1176 28.6508 11.7368C28.6508 10.3561 29.77 9.23685 31.1508 9.23685C32.5315 9.23685 33.6508 10.3561 33.6508 11.7368C33.6508 13.1176 32.5315 14.2368 31.1508 14.2368Z'
        fill={fill}
      />
      <path
        d='M10 33.5324C8.61929 33.5324 7.5 32.4131 7.5 31.0324C7.5 29.6517 8.61929 28.5324 10 28.5324C11.3807 28.5324 12.5 29.6517 12.5 31.0324C12.5 32.4131 11.3807 33.5324 10 33.5324Z'
        fill={fill}
      />
      <path d='M8.5 10.2368V13.579H11.5V13.2368H12.5V10.2368H8.5Z' fill={fill} />
      <path d='M15 13.2368H20.8042V10.2368H15V13.2368Z' fill={fill} />
      <path d='M24.1084 13.2368H27.4126V10.2368H24.1084V13.2368Z' fill={fill} />
      <path d='M11.5 15.4211H8.5V19.636H11.5V15.4211Z' fill={fill} />
      <path d='M11.5 26.7544V22.0088H8.5V26.7544H11.5Z' fill={fill} />
    </svg>
  );
};

export default IconIntelligentScissor;
