// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconMagnifyingGlassPlus = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-magnifying-glass-plus'}
      data-name='svg-magnifying-glass-plus'
      data-testid='svg-magnifying-glass-plus'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 24 24'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path d='M11 6.51804V10.018H14.5V11.518H11V15.018H9.5V11.518H6V10.018H9.5V6.51804H11Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 10.7679C18 12.4299 17.522 13.9709 16.615 15.2389L21.1185 19.7374L19.352 21.4999L14.8515 17.0049C13.5135 17.9689 11.849 18.5179 10.25 18.5179C5.9765 18.5179 2.5 15.0414 2.5 10.7679C2.5 6.49444 5.9765 3.01794 10.25 3.01794C14.5235 3.01794 18 6.49444 18 10.7679ZM4.8225 10.7109C4.8225 13.7434 7.29 16.2109 10.3225 16.2109C13.355 16.2109 15.8225 13.7434 15.8225 10.7109C15.8225 9.24194 15.251 7.86094 14.212 6.82244C13.1735 5.78394 11.792 5.21144 10.3225 5.21144C7.2905 5.21144 4.823 7.67894 4.8225 10.7109Z'
      />
    </svg>
  );
};

export default IconMagnifyingGlassPlus;
