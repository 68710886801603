// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import styled from 'styled-components';

interface InfoTagProps {
  backgroundColor?: string;
  color?: string;
}

export const InfoTag = styled.div<InfoTagProps>`
  background: ${({ backgroundColor }) => backgroundColor ?? COLORS.trgrey1.hex};
  border: 1px solid transparent;
  color: ${({ color }) => color ?? COLORS.trgrey6.hex};
  padding: 0 0.25rem;
  white-space: nowrap;
`;
