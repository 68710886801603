// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconTouchLoading = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-filter'}
      data-name='svg-icon-filter'
      data-testid='svg-icon-filter'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '15rem'}
      height={height ?? '8rem'}
      viewBox={viewBox ?? '0 0 198 158'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'none'}
      fill={fill ?? 'none'}
      {...other}
    >
      <g clipPath='url(#clip0_818_25318)'>
        <path
          d='M146.144 39.3999H49.2854C45.6587 39.3999 42.7188 42.3399 42.7188 45.9666V111.633C42.7188 115.26 45.6587 118.2 49.2854 118.2H146.144C149.77 118.2 152.71 115.26 152.71 111.633V45.9666C152.71 42.3399 149.77 39.3999 146.144 39.3999Z'
          fill='black'
        />
        <path d='M144.503 47.6084H50.9277V109.992H144.503V47.6084Z' fill='white' fillOpacity='0.9' />
        <path d='M64.0611 47.6084H50.9277V57.4584H64.0611V47.6084Z' fill='#285172' />
        <path d='M144.502 47.6084H64.0605V57.4584H144.502V47.6084Z' fill='#142939' />
        <path
          d='M144.502 39.3999H101.818L144.502 118.2C148.128 118.2 151.068 115.26 151.068 111.633V45.9666C151.068 42.3399 148.128 39.3999 144.502 39.3999Z'
          fill='url(#paint1_linear_818_25318)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M93.9908 93.0781L95.9096 90.3378C96.6003 90.6123 97.3441 90.7817 98.1213 90.8264L98.7021 94.1203C98.6468 94.1212 98.5914 94.1216 98.5359 94.1216C96.9061 94.1216 95.3638 93.7466 93.9908 93.0781ZM91.3025 91.193C90.1441 90.0708 89.2459 88.6815 88.7073 87.1244L92.0024 86.5434C92.3059 87.2458 92.7193 87.8896 93.221 88.4531L91.3025 91.193ZM88.14 83.8904C88.1391 83.8352 88.1387 83.7798 88.1387 83.7244C88.1387 82.0944 88.5138 80.552 89.1823 79.1788L91.9227 81.0976C91.6481 81.7883 91.4786 82.5323 91.4339 83.3096L88.14 83.8904ZM91.0676 76.4906C92.1898 75.3323 93.579 74.4343 95.136 73.8957L95.717 77.1908C95.0147 77.4942 94.371 77.9076 93.8075 78.4092L91.0676 76.4906ZM98.37 73.3284C98.4252 73.3276 98.4805 73.3271 98.5359 73.3271C104.278 73.3271 108.933 77.9821 108.933 83.7244C108.933 88.2758 106.009 92.1442 101.936 93.5529L101.355 90.2578C103.882 89.1661 105.65 86.6517 105.65 83.7244C105.65 79.9347 102.687 76.8372 98.9508 76.6224L98.37 73.3284Z'
          fill='#285172'
        />
        <path
          d='M124.401 110.67L121.923 110.673L121.923 87.0195C121.923 84.4086 119.802 82.2837 117.192 82.2837C114.58 82.2837 112.46 84.4088 112.46 87.0195L112.46 112.428L112.46 112.463L112.463 112.497L112.508 113.149L112.471 119.586L111.893 119.586C110.729 118.487 107.641 115.489 105.616 113.512C104.724 112.623 103.533 112.13 102.271 112.13C99.659 112.13 97.5352 114.254 97.5352 116.866C97.5352 118.128 98.0284 119.32 98.9213 120.215L98.9226 120.216L113.709 134.982C118.219 141.549 125.652 145.467 133.61 145.467C146.917 145.467 157.744 134.642 157.744 121.333L157.744 108.35C157.744 106.965 157.061 105.864 156.096 105.14C155.149 104.429 153.923 104.067 152.71 104.067C151.486 104.067 150.368 104.437 149.549 105.213C148.722 105.996 148.3 107.096 148.3 108.35L148.3 110.641L145.803 110.644L145.803 108.35C145.803 107.055 145.314 105.953 144.443 105.186C143.587 104.431 142.44 104.067 141.218 104.067C138.839 104.067 136.341 105.532 136.341 108.35L136.341 110.656L133.863 110.66L133.863 108.35C133.863 106.978 133.217 105.876 132.266 105.144C131.333 104.426 130.121 104.067 128.906 104.067C127.683 104.067 126.548 104.432 125.708 105.197C124.857 105.971 124.401 107.073 124.401 108.35L124.401 110.67Z'
          fill='#285172'
          stroke='white'
          strokeWidth='2'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_818_25318'
          x1='177.995'
          y1='34.1959'
          x2='120.773'
          y2='141.132'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0.05' />
          <stop offset='1' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_818_25318'
          x1='137.897'
          y1='102.139'
          x2='103.138'
          y2='59.3823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='1' stopColor='white' stopOpacity='0.3' />
        </linearGradient>
        <clipPath id='clip0_818_25318'>
          <rect width='197' height='157.6' fill='white' transform='translate(0.0351562)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconTouchLoading;
