// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import NoProjects from './NoProjects';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import Tile from './tile';
import { useAppDispatch } from 'store/hooks';
import { fetchProjects } from 'store/reducers/projectsSlice';
import useFilteredProjects from 'hooks/useFilteredProjects';
import { UseCase } from '@tls/sw91-communication/types/com.base';
import { Atoms, COLORS, Molecules } from '@tls/treact-ui';
import useAppPermissions from 'hooks/useAppPermissions';
import { useTranslation } from 'react-i18next';
import LicenseInfo from 'components/common/LicenseInfo';
import { AppPermission } from 'model/AppPermission';

export default function Tiles() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { loading, lastStatus, filteredProjects } = useFilteredProjects();
  const { hasCreateCDProjectPermission, hasCreateQCProjectPermission } = useAppPermissions();
  const filteredByUseCase = useMemo(
    () => ({
      cdProjects: filteredProjects.filter(p => p.projectUseCase?.useCase === UseCase.COMPONENT_DETECTION),
      qcProjects: filteredProjects.filter(p => p.projectUseCase?.useCase === UseCase.QUALITY_CHECK),
    }),
    [filteredProjects]
  );

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const renderProjects = projects =>
    projects.length > 0 ? projects.map(p => <Tile key={p.projectId} entry={p} />) : <></>;

  useEffect(() => {
    //toast error message
  }, [lastStatus]);

  if (!loading && filteredProjects.length < 1) {
    return <NoProjects />;
  }
  return (
    <Container>
      <BackdropLoading loading={loading && filteredProjects.length === 0} />
      {(hasCreateCDProjectPermission || filteredByUseCase.cdProjects.length > 0) && (
        <CollapsibleSection
          defaultOpen
          label={
            <Header>
              <BoldLabel>{t('project.settings.dialog.componentDetection.title')}</BoldLabel>
            </Header>
          }
        >
          <LicenseInfo appPermission={AppPermission.CreateCDProject} />
          <Row>
            <ProjectGrid>{renderProjects(filteredByUseCase.cdProjects)}</ProjectGrid>
          </Row>
        </CollapsibleSection>
      )}
      {hasCreateCDProjectPermission && hasCreateQCProjectPermission && <Atoms.Divider headline='' label='' />}

      {(hasCreateQCProjectPermission || filteredByUseCase.qcProjects.length > 0) && (
        <CollapsibleSection
          defaultOpen
          label={
            <Header>
              <BoldLabel>{t('project.settings.dialog.qualityControl.title')}</BoldLabel>
            </Header>
          }
        >
          <LicenseInfo appPermission={AppPermission.CreateQCProject} />
          {filteredByUseCase?.qcProjects && filteredByUseCase.qcProjects.length === 0 && <NoProjects />}
          {filteredByUseCase?.qcProjects && filteredByUseCase.qcProjects.length > 0 && (
            <Row>
              <ProjectGrid>{renderProjects(filteredByUseCase.qcProjects)}</ProjectGrid>
            </Row>
          )}
        </CollapsibleSection>
      )}
    </Container>
  );
}

// Styled Components
const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
  padding: 1rem;
`;

const Row = styled.div`
  position: relative;
  gap: 1rem;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 18rem);
  gap: 1rem;
  grid-auto-rows: auto;
  justify-items: start;
  align-items: start;
`;

const Header = styled.span`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  align-items: center;
  margin-left: 1rem;
`;

const BoldLabel = styled.span`
  font-weight: bold;
  color: ${COLORS.trblue35.hex};
`;

const CollapsibleSection = ({ defaultOpen, label, children }) => (
  <Molecules.Collapsible defaultOpen={defaultOpen} label={label}>
    {children}
  </Molecules.Collapsible>
);
