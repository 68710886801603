// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const reactPlugin = new ReactPlugin();

const browserHistory = createBrowserHistory();

function setUpAppInsights(connectionString: string, instrumentationKey: string) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      instrumentationKey,
      extensions: [reactPlugin],
      disableTelemetry: !connectionString || !instrumentationKey,
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();
}

export { reactPlugin, setUpAppInsights };
