// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, COLORS, ICONS } from '@tls/treact-ui';
import IconExport from 'components/treactui-template/icons/IconExport';
import { GetModelTitleTranslationKey, ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ModeLBoxPlot from './ModelBoxPlot';
import ImagePreviewContainer from './ImagePreviewContainer';

export type Props = {
  version: ProjectVersion;
  onExport: () => void;
  loading: boolean;
  disabled?: boolean;
  evaluationId?: string;
};

export default function ModelResult({ version, onExport, loading, disabled, evaluationId }: Props) {
  const { t } = useTranslation();
  const { id } = useParams();

  const versionString = t(version ?? '');

  const { IconLoading } = ICONS;

  return (
    <Container>
      <ChartContainer>
        <span className='font-size_l'>{t(GetModelTitleTranslationKey(version), { version: versionString })}</span>
        <ModeLBoxPlot projectId={id ?? ''} modelId={version} evaluationId={evaluationId} />
      </ChartContainer>
      <ImageContainer>
        <ImagePreviewContainer version={version} />
      </ImageContainer>
      <Atoms.Button
        icon={loading ? <IconLoading /> : <IconExport />}
        label={t('project.evaluation.export.title', { version: '' })}
        onClick={onExport}
        disabled={loading || disabled}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: calc(50% - 2.75rem) calc(50% - 2.75rem) 2.5rem;
  row-gap: 1rem;
  position: relative;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const ImageContainer = styled.div`
  border: 1px solid ${COLORS.trgrey5.hex};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
