// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { COLORS } from '@tls/treact-ui';
import styled from 'styled-components';
import ImageFooter from './label/ImageFooter';
import ImagesList from './label/ImagesList';
import LabelImage from './label/LabelImage';
import LabelTools from './label/LabelTools';
import TrainingStatusChart from './label/TrainingStatusChart';
import ImageListOrderProvider from './label/images/ImageListOrderProvider';
import LabelSelection from './label/tools/LabelSelection';

export default function Label() {
  return (
    <Container>
      <ImagesContainer>
        <ImageListOrderProvider>
          <ImageContainer>
            <ImagesList />
          </ImageContainer>
          <ImageEditContainer>
            <LabelImage />
          </ImageEditContainer>
          <FooterContainer>
            <ImageFooter />
          </FooterContainer>
        </ImageListOrderProvider>
      </ImagesContainer>
      <RightSideContainer>
        <RightSideElement>
          <LabelSelection />
        </RightSideElement>
        <RightSideElement>
          <LabelTools />
        </RightSideElement>
        <RightSideElement>
          <TrainingStatusChart />
        </RightSideElement>
      </RightSideContainer>
    </Container>
  );
}

const ImageContainer = styled.div`
  grid-area: images;
  position: relative;
  border-right: 1px solid ${COLORS.trgrey2.hex};
`;

const ImageEditContainer = styled.div`
  grid-area: image-edit;
`;

const FooterContainer = styled.div`
  grid-area: footer;
  .mirror {
    transform: scaleX(-1);
  }
`;

const ImagesContainer = styled.div`
  grid-area: image;
  display: grid;
  grid-template:
    'images image-edit'
    'footer footer';
  grid-template-columns: 26rem auto;
  grid-template-rows: calc(100% - 4rem);
  border: 1px solid ${COLORS.trgrey2.hex};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;
const RightSideContainer = styled.div`
  grid-area: right-side;
  height: 100%;
  overflow: auto;
`;

const RightSideElement = styled.div`
  border: 1px solid ${COLORS.trgrey2.hex};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.5rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 'image right-side';
  grid-template-columns: auto 28rem;
  gap: 0.5rem;
  height: calc(100% - 1rem);
  margin: 0.5rem;
  min-width: 1172px;
`;
