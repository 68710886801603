// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback } from 'react';
import { Atoms, ICONS } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { toggleImportDialog } from 'store/reducers/projectSettingsSlice';
import ImportProjectDialog from 'components/projectSelection/ImportProjectDialog';
import useAppPermissions from 'hooks/useAppPermissions';

export default function ImportProjectButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hasCreateCDProjectPermission, hasCreateQCProjectPermission } = useAppPermissions();

  const onOpen = useCallback(() => {
    dispatch(toggleImportDialog());
  }, [dispatch]);

  const { IconSave } = ICONS;

  return (
    <>
      <Atoms.Button
        icon={<IconSave />}
        variant='secondary'
        onClick={onOpen}
        className='fill'
        label={t('projects.importProject')}
        disabled={!hasCreateCDProjectPermission && !hasCreateQCProjectPermission}
      />
      <ImportProjectDialog />
    </>
  );
}
