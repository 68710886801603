// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import useAllContractApi from 'hooks/useAllContractsApi';
import React from 'react';
import ContractsTable from './ContractsTable';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import { useAppSelector } from 'store/hooks';

export default function Contracts() {
  const { data, isLoading } = useAllContractApi();
  const filterText = useAppSelector(s => s.uiSettings.filterText);

  return (
    <PageContent displayAsPaper disableScroll>
      <ContractsTable filterText={filterText} data={data || []} isLoading={isLoading || false} />
    </PageContent>
  );
}
