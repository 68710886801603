// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconLabel = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-filter'}
      data-name='svg-icon-filter'
      data-testid='svg-icon-filter'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.5rem'}
      height={height ?? '2.55rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <circle cx='18' cy='18' r='17' fill={fill} />
    </svg>
  );
};

export default IconLabel;
