// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, FLAGS, Organisms } from '@tls/treact-ui';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { useModelExportApi } from 'hooks/useModelExportApi';
import { GetExportTranslationKey, ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ModelResult from './ModelResult';
import EvaluateContext from './EvaluateContext';

export type Props = {
  selected: ProjectVersion[];
  evaluationId?: string;
};

const InformationFlag = FLAGS.COMPONENTS.information;

export default function ModelComparison({ selected, evaluationId }: Props) {
  const { id } = useParams();
  const [closed, setClosed] = useState(true);
  const [version, setVersionString] = useState<ProjectVersion>();
  const { loading, lastStatus, downloadAndSaveFile, triggerExport, getExportResult, exportProgress } =
    useModelExportApi(id ?? '');

  const { t } = useTranslation();
  const triggerAndDownloadModel = useCallback(
    async (version: ProjectVersion) => {
      setVersionString(version);
      setClosed(false);

      try {
        await triggerExport(version);
        const success = await getExportResult();

        if (!success) return;
        await downloadAndSaveFile(version);
      } finally {
        setClosed(true);
      }
    },
    [downloadAndSaveFile, getExportResult, triggerExport]
  );

  const evaluate = useContext(EvaluateContext);
  const onExport = useCallback(
    (version: ProjectVersion) => () => {
      void triggerAndDownloadModel(version)
        .catch(e => {
          console.error('Unable to export model', e);
          ToastByStatus(503, t('project.evaluation.title'), t('project.evaluation.export.error', { version: version }));
        })
        .then(async () => {
          await evaluate.refresh();
        });
    },
    [evaluate, t, triggerAndDownloadModel]
  );

  const onCloseDialog = useCallback(() => setClosed(true), []);

  useEffect(() => {
    if (lastStatus === 200) return;

    ToastByStatus(
      lastStatus,
      t('project.evaluation.title'),
      t('project.evaluation.export.error', { version: version })
    );
  }, [lastStatus, t, version]);

  const progress = Math.min(100, exportProgress * 100);

  return (
    <Container columns={selected.length}>
      {selected.map(s => (
        <ModelResult
          key={s}
          onExport={onExport(s)}
          version={s}
          loading={loading && s === version}
          disabled={loading}
          evaluationId={evaluationId}
        />
      ))}
      <Organisms.Dialog enableClosing={false} open={!closed} onClose={onCloseDialog}>
        <Organisms.Dialog.DialogHeader label={t(GetExportTranslationKey(version), { version })} />
        <Organisms.Dialog.DialogContent>
          <LoaderContainer>
            <ProgressContainer>
              <span>{t('project.evaluation.export.progress')}</span>
              <Atoms.ProgressBar percentage={progress} width={100} />
              <span className='font-size_xl'>{progress.toFixed(0)}%</span>
            </ProgressContainer>
            <Info>
              <InformationFlag width='1.25rem' />
              <span>
                {t('project.evaluation.export.visionLineInfo', { vlVersion: '2.08', panelVersion: '3.56.0' })}
              </span>
            </Info>
          </LoaderContainer>
        </Organisms.Dialog.DialogContent>
        <Organisms.Dialog.Footer
          right={[<Atoms.FooterButton key='cancel' label={t('cancel')} onClick={onCloseDialog} variant='secondary' />]}
        />
      </Organisms.Dialog>
    </Container>
  );
}
const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const Container = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => `${columns}`}, 1fr);
  height: 100%;
  margin: 0.5rem;
  column-gap: 1rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
