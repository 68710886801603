// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useMemo, useCallback } from 'react';
import { Molecules } from '@tls/treact-ui';
import { getStrategyOptions } from 'hooks/projectSettingsHooks/useProjectStrategy';
import { useTranslation } from 'react-i18next';
import { StrategyType } from '@tls/sw91-communication/types/com.base';

export const StrategySelector = ({ usecase, projectStrategy, setProjectStrategy, lockSelection }) => {
  const { t } = useTranslation();
  const strategyOptions = useMemo(() => getStrategyOptions(usecase), [usecase]);

  const handleStrategyChange = useCallback(
    option => {
      if (lockSelection) return;
      setProjectStrategy(option);
    },
    [setProjectStrategy, lockSelection]
  );

  return (
    <Molecules.SegmentedControl flag='none' label={t('project.settings.dialog.modelType')}>
      {strategyOptions.map(option => (
        <Molecules.SegmentedControl.SegmentedControlButton
          disabled={lockSelection && projectStrategy.id !== option.id}
          key={option.id}
          label={t(`project.strategy.${option.labelKey}`)}
          selected={projectStrategy.id === option.id}
          onClick={() => handleStrategyChange(option)}
        />
      ))}
    </Molecules.SegmentedControl>
  );
};

export const StrategyIdentifier = ({ projectStrategy }) => {
  const { t } = useTranslation();

  const strategyLabels = useMemo(
    () => ({
      [StrategyType.SIMPLE_STRATEGY]: t('project.strategy.highSpeed'),
      [StrategyType.TWO_STEP_STRATEGY]: t('project.strategy.highPrecision'),
    }),
    [t]
  );

  return strategyLabels[projectStrategy] || t('project.strategy.unknown');
};
