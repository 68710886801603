// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { del, post, put } from '@tls/react-saf-ui';
import { StrategyType, ModelClassMessage, UseCase } from '@tls/sw91-communication/types/com.base';
import { useCallback, useState } from 'react';

export type SaveProps = {
  name: string;
  tags: string[];
  labels: ModelClassMessage[];
  strategy: StrategyType;
  useCase: UseCase;
};
export type EditProps = { id: string } & SaveProps;

export default function useProjectSelectionApi() {
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(200);

  const save = useCallback(async ({ name, labels, tags, strategy, useCase }: SaveProps) => {
    setLoading(true);
    const { data, status, statusText } = await post('projects', {
      projectName: name,
      tags: tags,
      modelClasses: labels,
      strategy: strategy,
      useCase: useCase,
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const edit = useCallback(async ({ name, tags, id, labels, strategy, useCase }: EditProps) => {
    setLoading(true);
    const { data, status, statusText } = await put(`projects/${id}`, {
      projectName: name,
      tags: tags,
      strategy: strategy,
      modelClasses: labels,
      useCase: useCase,
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const duplicate = useCallback(async ({ name, tags, id, labels, strategy, useCase }: EditProps) => {
    setLoading(true);
    const { data, status, statusText } = await post(`projects/${id}/duplicate`, {
      projectName: name,
      tags: tags,
      strategy: strategy,
      modelClasses: labels,
      useCase: useCase,
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const remove = useCallback(async (id: string) => {
    setLoading(true);

    const { data, status, statusText } = await del(`projects/${id}`);
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  return { save, edit, remove, duplicate, loading, statusCode };
}
