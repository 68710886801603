// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconExport = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-export'}
      data-name='svg-icon-export'
      data-testid='svg-icon-export'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.5rem'}
      height={height ?? '2.55rem'}
      viewBox={viewBox ?? '0 0 36 36'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.75 16.5H13V19.5H26.75V23.5L35.9164 18L26.75 12.5V16.5Z'
        fill={fill}
      />
      <path d='M4 4H32V7H35V1H1V35H35V29H32V32H4V4Z' fill={fill} />
    </svg>
  );
};

export default IconExport;
