// SPDX-License-Identifier: LicenseRef-TRUMPF
// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//

import { useCallback, useEffect, useMemo, useState } from 'react';
import { LabelInfo } from 'components/projectSelection/common/ProjectSettingTypes';
import { useTranslation } from 'react-i18next';
import { colorOptions } from 'components/common/ColorOptions';

type Category = {
  label: string;
  value?: string;
};

export default function useProjectLabelManagement(initialLabels: LabelInfo[] = [], initialCategories: Category[] = []) {
  const { t } = useTranslation();
  const labelName = t('project.label.title');
  const labelCategory = t('project.label.category');

  const [labelsInfo, setLabelsInfo] = useState<LabelInfo[]>(initialLabels);
  const [categories, setCategories] = useState<Category[]>(initialCategories);
  const availableColorOptions = useMemo(
    () => colorOptions.filter(color => !labelsInfo.map(label => label?.labelColor?.id).includes(color?.id)),
    [labelsInfo]
  );
  const addLabel = useCallback(() => {
    if (labelsInfo.length < 5) {
      setLabelsInfo([...labelsInfo, { labelName: labelName, labelColor: availableColorOptions[0], labelCategory: '' }]);
    }
    setCategories([...categories, { label: labelCategory }]);
  }, [labelsInfo, labelName, categories, labelCategory, availableColorOptions]);

  const removeLabel = useCallback(
    (index: number) => {
      setLabelsInfo(labelsInfo.filter((_, i) => i !== index));
      setCategories(categories.filter((_, i) => i !== index));
    },
    [labelsInfo, categories]
  );

  const updateLabel = useCallback((index: number, updatedLabel: LabelInfo) => {
    setLabelsInfo(prevLabels => {
      const newLabels = [...prevLabels];
      newLabels[index] = updatedLabel;
      return newLabels;
    });
  }, []);

  useEffect(() => {
    if (categories.length === 0) {
      setCategories([{ label: labelCategory }]);
    }
  }, [categories, labelCategory]);

  return {
    labelName,
    labelsInfo,
    categories,
    addLabel,
    removeLabel,
    updateLabel,
    setCategories,
    setLabelsInfo,
    availableColorOptions,
  };
}
