// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS, Molecules } from '@tls/treact-ui';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import useProjectTrainingApi from 'hooks/useProjectTrainingApi';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { GetStatusTranslationKey, ProjectStatusIcon } from 'model/ProjectMetaMessageExtensions';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useTrainingStatusApi from 'hooks/useTrainingStatusApi';
import useProjectLabels from 'hooks/useProjectLabels';
import { ProjectStatus } from 'model/ProjectMetadata';
import TrainingChart from './chart/TrainingChart';
import NoContentBackdrop from 'components/treactui-template/organisms/backdrop/NoContentBackdrop';
import useAppPermissions from 'hooks/useAppPermissions';

const { IconLoading, IconPlay, IconStop } = ICONS;

function getLabel(status?: ProjectStatus) {
  if (status === ProjectStatus.TRAINING_ACTIVE) return 'project.label.training.stop';
  return 'project.label.training.start';
}

function getIcon(status?: ProjectStatus, isLoading?: boolean) {
  if (isLoading) return <IconLoading width={'1.5rem'} />;
  if (status === ProjectStatus.TRAINING_ACTIVE) return <IconStop />;
  return <IconPlay />;
}

export default function TrainingStatusChart() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { selectedProject } = useProjectsMetadata(id);
  const status = selectedProject?.projectStatus;

  const { labels } = useProjectLabels();

  const { history = [], loading } = useProjectTrainingApi(id ?? '');
  const { changeTrainingStatus, loading: loadingTraining, status: changeStatus } = useTrainingStatusApi(id ?? '');

  const { hasPermissionForProject } = useAppPermissions();

  const onStatusChange = useCallback(() => {
    changeTrainingStatus(
      selectedProject?.projectStatus === ProjectStatus.TRAINING_ACTIVE
        ? ProjectStatus.TRAINING_STOPPED
        : ProjectStatus.TRAINING_ACTIVE
    );
  }, [changeTrainingStatus, selectedProject?.projectStatus]);

  return (
    <Container>
      <Molecules.Collapsible
        defaultOpen
        label={
          <Header>
            <ProjectStatusIcon status={status} width='1.5rem' />
            <LabelText>{t(GetStatusTranslationKey(status, changeStatus === 200 && loadingTraining))}</LabelText>
            <Spacer />
            <LabelButton>
              <Atoms.Button
                icon={getIcon(status, loadingTraining)}
                label={t(getLabel(status))}
                variant='secondary'
                disabled={loadingTraining || labels.length < 1 || !hasPermissionForProject}
                onClick={onStatusChange}
              />
            </LabelButton>
          </Header>
        }
      >
        <ChartContainer>
          <TrainingChart history={history} />
          <NoContentBackdrop
            show={history.length < 1 && !loading}
            title={t('project.label.training.chart.noData.title')}
            details={t('project.label.training.chart.noData.details')}
          />
          <BackdropLoading label={t('loading')} loading={loading && history.length < 1} />
        </ChartContainer>
      </Molecules.Collapsible>
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5rem;
  height: calc(100% - 0.5rem);
  .font-size_m {
    width: 100%;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  height: 320px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const LabelText = styled.span`
  flex: 1;
  width: 80%;
`;

const Spacer = styled.div`
  flex: 1;
`;

const LabelButton = styled.div``;
