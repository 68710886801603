// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { useSafContext } from '@tls/react-saf-ui';
import { MenuItemType, UxcAppHeaderCustomEvent } from '@trumpf/ux.application-framework';
import { UxcAppHeader } from '@trumpf/ux.application-framework-react';
import { NavbarContext, NavbarContextType } from 'components/treactui-template/organisms/page/NavbarContext';
import useDownloadFileApi from 'hooks/useDownloadApi';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setReturnRoute, toggleSettings, toggleThirdPartyLicenses } from 'store/reducers/uiSettingsSlice';
import { AppRoutes } from 'utilities/router/AppRoutes';

const licenseSlug = 'help:license';
const dataUsageSlug = 'settings:userData';
const usersSettingsSlug = 'settings:users';
const rolesSettingsSlug = 'settings:roles';
const contractsSlug = 'settings:contracts';
const documentationSlug = 'help:documentation';

export default function Nav() {
  const ref = useRef<HTMLUxcAppHeaderElement>(null);
  const value: NavbarContextType = useMemo(() => ({ navbarRef: ref }), [ref]);

  const { currentUser } = useSafContext();
  const navigate = useNavigate();
  const onLogout = useCallback(() => {
    navigate('logout');
  }, [navigate]);

  const { downloadAndSaveFile } = useDownloadFileApi();

  const { t } = useTranslation();
  const helpItems = useMemo<MenuItemType[]>(
    () => [
      {
        label: t('thirdPartyLicenses.title'),
        slug: licenseSlug,
        selected: false,
      },
      {
        label: t('help.documentation'),
        slug: documentationSlug,
        selected: false,
      },
    ],
    [t]
  );

  const dispatch = useAppDispatch();
  const onMenuChange = useCallback(
    (
      item: UxcAppHeaderCustomEvent<{
        name: string;
        current: string | null;
      }>
    ) => {
      function setReturnUrlAndNavigate(newUrl: string) {
        if (!location.pathname.startsWith(AppRoutes.Settings)) {
          dispatch(setReturnRoute(location.pathname));
        }
        navigate(newUrl);
      }

      if (item.detail.current === licenseSlug) dispatch(toggleThirdPartyLicenses());
      if (item.detail.current === dataUsageSlug) dispatch(toggleSettings());
      if (item.detail.current === usersSettingsSlug) setReturnUrlAndNavigate(AppRoutes.SettingsUser);
      if (item.detail.current === rolesSettingsSlug) setReturnUrlAndNavigate(AppRoutes.SettingsRole);
      if (item.detail.current === contractsSlug) setReturnUrlAndNavigate(AppRoutes.SettingsContracts);
      if (item.detail.current === documentationSlug)
        downloadAndSaveFile('documentation', 'trumpf-easymodel-ai-documentation.pdf');
    },
    [dispatch, navigate, downloadAndSaveFile]
  );

  const isLicenseOpen = useAppSelector(s => s.uiSettings.showThirdPartyLicenses);
  const isSettingsOpen = useAppSelector(s => s.uiSettings.showSettings);
  const { pathname } = useLocation();
  let slug = '';
  if (isLicenseOpen) {
    slug = licenseSlug;
  } else if (isSettingsOpen) {
    slug = dataUsageSlug;
  } else if (pathname === AppRoutes.SettingsUser) {
    slug = usersSettingsSlug;
  } else if (pathname === AppRoutes.SettingsRole) {
    slug = rolesSettingsSlug;
  } else if (pathname === AppRoutes.SettingsContracts) {
    slug = contractsSlug;
  }

  const settingsNavigation = [
    {
      label: t('settings.data.title'),
      slug: dataUsageSlug,
      selected: false,
    },
  ];

  if (currentUser?.roles['SuperAdmin'] ?? false) {
    settingsNavigation.push({
      label: t('settings.users.title'),
      slug: usersSettingsSlug,
      selected: false,
    });
    settingsNavigation.push({
      label: t('settings.roles.title'),
      slug: rolesSettingsSlug,
      selected: false,
    });
    settingsNavigation.push({
      label: t('settings.contracts.title'),
      slug: contractsSlug,
      selected: false,
    });
  }

  return (
    <>
      <section ref={ref}>
        <UxcAppHeader
          mainNavigation={[]}
          disableMenuLabel
          activeMenuSlug={slug}
          userData={{
            userName: currentUser?.userName ?? '',
            displayName: `${currentUser?.firstName} ${currentUser?.lastName}`,
            locale: currentUser?.language,
            email: currentUser?.email,
          }}
          settingsNavigation={settingsNavigation}
          onUicLogout={onLogout}
          onUicMenuChange={onMenuChange}
          helpNavigation={helpItems}
          keepHelpInTitleBar={true}
        />
      </section>
      <NavbarContext.Provider value={value}>
        <Outlet />
      </NavbarContext.Provider>
    </>
  );
}
