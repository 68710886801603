// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import { BoxPlotType } from 'hooks/useModelEvaluationApi';
import { ModelBoxPlotChartType, OptionType } from './defaults';
import { ModelClassMessage } from '@tls/sw91-communication/types/com.base';
import { getColorOption } from 'components/common/ColorOptions';

export function SetBoxPlotOptions(boxPlots: BoxPlotType[], options: OptionType, modelClasses?: ModelClassMessage[]) {
  boxPlots.forEach((boxPlot: BoxPlotType, index) => {
    const { thirdQuartile, firstQuartile, median, lowerWhisker, upperWhisker } = boxPlot;
    const annotations = options.plugins?.annotation?.annotations;

    if (!annotations) return;

    const top = Math.max(thirdQuartile, firstQuartile);
    const bottom = Math.min(thirdQuartile, firstQuartile);
    const topWhisk = Math.max(lowerWhisker, upperWhisker);
    const bottomWhisk = Math.min(lowerWhisker, upperWhisker);

    const offset = index / 2;

    annotations['box' + index] = {
      type: 'box',
      xMin: -0.1 + offset,
      xMax: 0.1 + offset,
      yMin: bottom,
      yMax: top,
      borderWidth: 1,
      backgroundColor: `rgba(${getColorOption(modelClasses?.[index]?.labelColor).id}, 0.25)`,
    };

    annotations['median' + index] = {
      xMax: 0.1 + offset,
      xMin: -0.1 + offset,
      yMax: median,
      yMin: median,
      borderWidth: 1.5,
      type: 'line',
    };

    annotations['horizontalUpperWhisk' + index] = {
      xMax: 0.075 + offset,
      xMin: -0.075 + offset,
      yMax: topWhisk,
      yMin: topWhisk,
      borderWidth: 1.5,
      type: 'line',
    };

    annotations['verticalUpperWhisk' + index] = {
      xMin: 0 + offset,
      xMax: 0 + offset,
      yMax: top,
      yMin: topWhisk,
      borderWidth: 1.5,
      type: 'line',
    };

    annotations['verticalLowerWhisk' + index] = {
      xMin: 0 + offset,
      xMax: 0 + offset,
      yMax: bottom,
      yMin: bottomWhisk,
      borderWidth: 1.5,
      type: 'line',
    };

    annotations['horizontalLowerWhisk' + index] = {
      xMax: 0.075 + offset,
      xMin: -0.075 + offset,
      yMax: bottomWhisk,
      yMin: bottomWhisk,
      borderWidth: 1.5,
      type: 'line',
    };
  });
}

export function setHighlightedData(
  data: ModelBoxPlotChartType,
  selectedIdxs: number[],
  modelClasses?: ModelClassMessage[],
  activeId?: number
) {
  data.datasets.forEach((dataset, index) => {
    for (let idx = 0; idx < dataset.data.length; idx++) {
      dataset.pointStyle![idx] = 'crossRot';
      if (idx === activeId) {
        dataset.pointBorderWidth![idx] = 4;
        dataset.pointRadius![idx] = 10;
        dataset.pointBorderColor![idx] = `rgb(${getColorOption(modelClasses?.[index].labelColor).id})`;
      } else if (selectedIdxs.includes(idx)) {
        dataset.pointBorderWidth![idx] = 3;
        dataset.pointRadius![idx] = 9.5;
        dataset.pointBorderColor![idx] = `rgb(${getColorOption(modelClasses?.[index].labelColor).id})`;
      } else {
        dataset.pointBorderWidth![idx] = 1.5;
        dataset.pointRadius![idx] = 6;
        dataset.pointBorderColor![idx] = COLORS.trgrey3.hex;
      }
    }
  });
}
