// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import React from 'react';
import { UseCase } from '@tls/sw91-communication/types/com.base';
import { Atoms } from '@tls/treact-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HighSpeedImage from './HighSpeedImage';
import HighPrecisionImage from './HighPrecisionImage';

export const UseCaseAdditions = ({ useCase, isEditing }) => {
  const { t } = useTranslation();

  return (
    <>
      {useCase === UseCase.COMPONENT_DETECTION && (
        <Description>
          <DescriptionItem>
            <div style={{ flex: 1 }}>
              <HighSpeedImage />
            </div>
            <div style={{ flex: 3 }}>
              <Atoms.Headline as='h2' label={t('project.strategy.highSpeed')} />
              <div>{t('project.strategy.HighSpeedDescription')}</div>
            </div>
          </DescriptionItem>
          <DescriptionItem>
            <div style={{ flex: 1 }}>
              <HighPrecisionImage />
            </div>
            <div style={{ flex: 3 }}>
              <Atoms.Headline as='h2' label={t('project.strategy.highPrecision')} />
              <div>{t('project.strategy.HighPrecisionDescription')}</div>
            </div>
          </DescriptionItem>
        </Description>
      )}

      {useCase === UseCase.QUALITY_CHECK && (
        <Container>
          <Import>
            <FileUpload>
              <Atoms.InputFieldText
                label={t('projects.import.path')}
                onValueChange={function noRefCheck() {}}
                value=''
              />
              <EditButton>
                <Atoms.Button as='button' iconKey='IconFolderOpen' onClick={() => {}} variant='secondary' />
              </EditButton>
            </FileUpload>
          </Import>
          <FourColumns>
            <Atoms.InputFieldValue
              flag='none'
              helperText=''
              label={t('projects.create.qualityControl.xScaling')}
              max={100}
              min={-100}
              onValueChange={function noRefCheck() {}}
              suffix='mm/pixels'
              value={null}
            />
            <Atoms.InputFieldValue
              flag='none'
              helperText=''
              label={t('projects.create.qualityControl.yScaling')}
              max={100}
              min={-100}
              onValueChange={function noRefCheck() {}}
              suffix='mm/pixels'
              value={null}
            />
            <Atoms.InputFieldValue
              flag='none'
              helperText=''
              label={t('projects.create.qualityControl.rotation')}
              max={100}
              min={-100}
              onValueChange={function noRefCheck() {}}
              suffix='*'
              value={null}
            />
            {isEditing && (
              <EditButton>
                <Atoms.Button as='button' iconKey='IconEdit' onClick={() => {}} variant='secondary' />
              </EditButton>
            )}
          </FourColumns>
        </Container>
      )}
    </>
  );
};
const Container = styled.div`
  display: grid;
`;
const GridBase = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 38px;
  grid-gap: 8px;
`;

const Import = styled(GridBase)``;

const FourColumns = styled(GridBase)``;

const EditButton = styled.div`
  display: grid;
  place-items: center;
`;

const FileUpload = styled.div`
  display: grid;
  grid-template-columns: auto 50px;
  align-items: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  white-space: pre-line;
`;
const DescriptionItem = styled.div`
  gap: 0.5rem;
  justify-content: end;
  align-items: center;
  display: flex;
  flex: 2;
`;
