// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { UpdateProjectMetaAckMessage, UploadImageAckMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { AcknowledgeStatus } from '@tls/sw91-communication/types/com.base';
import { ImagesContext } from 'components/project/images/ImagesContext';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import useAppPermissions from 'hooks/useAppPermissions';
import useUploadApi from 'hooks/useUploadApi';
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { fetchProjects } from 'store/reducers/projectsSlice';

function imgToCanvas(img: CanvasImageSource, rawWidth: number, rawHeight: number) {
  const canvas = document.createElement('canvas');
  canvas.width = Math.max(rawWidth, 1);
  canvas.height = Math.max(rawHeight, 1);
  const ctx = canvas.getContext('2d');

  ctx?.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

async function reduceSize(file: File, maxWidth: number, maxHeight: number) {
  const waitOnLoad = new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
      console.error('Failed to load image', { event, source, lineno, colno, error });
      reject(error);
    };
    img.onload = function () {
      const canvas = imgToCanvas(img, maxWidth, maxHeight);
      canvas.toBlob(blob => {
        if (!blob) {
          return reject(new Error('Unable to parse Blob'));
        }

        const filename = `${file.name}.preview.jpg`;
        resolve(new File([blob], filename, { type: 'image/jpeg' }));
      }, 'image/jpeg');
    };
    img.src = URL.createObjectURL(file);
  });

  return await waitOnLoad;
}

export default function useFileUpload(noClick: boolean, setPreviewImage: boolean) {
  const { id } = useParams();
  const { loading, uploadFile, uploadPreviewImage } = useUploadApi(id ?? '');
  const [percentage, setPercentage] = useState(0);
  const [loadingAll, setLoadingAll] = useState(false);
  const dispatch = useAppDispatch();
  const { hasPermissionForProject } = useAppPermissions();
  const { t } = useTranslation();
  const uploadProjectPreviewImage = useCallback(
    async (file: File) => {
      const preview = await reduceSize(file, 218, 128);
      const { status, data } = await uploadPreviewImage(preview);
      const updateResponse = UpdateProjectMetaAckMessage.fromJSON(data);
      if (status !== 200 || !updateResponse || updateResponse.status?.status !== AcknowledgeStatus.SUCCESS) {
        return false;
      }
      dispatch(fetchProjects());
      return true;
    },
    [dispatch, uploadPreviewImage]
  );

  const { addImage } = useContext(ImagesContext);

  const handleOndrop = useCallback(
    async (acceptedFiles: File[]) => {
      setPercentage(0);
      setLoadingAll(true);
      let idx = 0;
      let setPreview = setPreviewImage;
      for (const file of acceptedFiles) {
        try {
          const reducedSize = await reduceSize(file, 218, 128);
          const { status, data } = await uploadFile(file, reducedSize);

          ToastByStatus(
            status,
            t('project.label.images.upload.title'),
            t('project.label.images.upload.error', { name: file.name })
          );

          setPercentage((++idx / acceptedFiles.length) * 100);
          const result = UploadImageAckMessage.fromJSON(data);
          if (result?.imageMeta && result?.status?.status === AcknowledgeStatus.SUCCESS) {
            addImage(result.imageMeta);

            if (setPreview && (await uploadProjectPreviewImage(file))) {
              setPreview = false;
            }
          } else if (result?.status?.status === AcknowledgeStatus.IMAGE_ALREADY_IN_PROJECT) {
            ToastByStatus(
              405,
              t('project.label.images.upload.title'),
              t('project.label.images.upload.duplicate', { name: file.name })
            );
          }
        } catch (ex) {
          console.error(ex);

          ToastByStatus(
            503,
            t('project.label.images.upload.title'),
            t('project.label.images.upload.error', { name: file.name })
          );
        }
      }
      setLoadingAll(false);
    },
    [setPreviewImage, uploadFile, t, uploadProjectPreviewImage, addImage]
  );

  const result = useDropzone({
    onDrop: handleOndrop,
    disabled: !hasPermissionForProject,
    noClick,
    accept: {
      'image/png': ['.png'],
      'image/bmp': ['.bmp'],
      'image/tiff': ['.tiff'],
    },
  });

  return { ...result, loading: loading || loadingAll, percentage };
}
