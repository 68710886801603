// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Molecules, Organisms } from '@tls/treact-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { toggleCreateDialog } from 'store/reducers/projectSettingsSlice';

const { Dialog } = Organisms;
const { FooterButton } = Atoms;
const { EmptyState } = Molecules;

export default function NoLicenseDialogContent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleCreateDialog());
  }, [dispatch]);

  return (
    <>
      <Dialog.DialogContent>
        <EmptyState iconType={'error'} label={t('projects.create.noLicense')} />;
      </Dialog.DialogContent>
      <Dialog.Footer
        right={[<FooterButton key='cancel' label={t('cancel')} onClick={onClose} variant='secondary' />]}
      />
    </>
  );
}
