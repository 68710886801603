// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useEffect, useMemo, useState } from 'react';
import { get } from '@tls/react-saf-ui';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { ToolState } from 'store/reducers/projectToolSlice';
import { PencilType, ToolMode, ToolType } from 'model/Tools';

import './webcomponents.d.ts';
import { blobToBase64 } from 'components/treactui-template/meta/loadImageIntoElement';
import NoContentBackdrop from 'components/treactui-template/organisms/backdrop/NoContentBackdrop';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import useToolHelpers from 'hooks/useToolHelpers';
import CanvasZoom from './CanvasZoom';
import useProjectsMetadata from 'hooks/useProjectsMetadata.js';

export type Props = {
  projectId?: string;
  imageId?: string;
  hasMask?: boolean;
};

type MaskResult = {
  data?: Blob;
  status: number;
  statusText: string;
};

function mapTools(tools: ToolState) {
  const tool =
    tools.toolType === ToolType.Polygon
      ? 'polygon-tool'
      : tools.toolType === ToolType.IntelligentScissor
        ? 'scissors'
        : tools.mode === ToolMode.Draw
          ? 'pencil'
          : 'eraser';

  const shape = tools.pencilType === PencilType.Circle ? 'circle' : 'rectangle';

  return { tool, shape };
}

function CanvasContainer({ projectId, imageId, hasMask }: Readonly<Props>) {
  const tools = useAppSelector(state => state.tools);

  const [loading, setLoading] = useState(true);
  const [imageExist, setImageExist] = useState(true);
  const { setStepFromBlob } = useToolHelpers();
  const { selectedProject } = useProjectsMetadata(projectId);

  useEffect(() => {
    if (!projectId || !imageId) {
      setImageExist(false);
      setLoading(false);
      return;
    }

    async function fetch() {
      setLoading(true);
      // We need to fetch both image and mask, otherwise the label will be erased.
      const imageGet = get(`projects/${projectId}/labels/${imageId}/image`, false, false, {
        responseType: 'blob',
      });

      let maskGet: Promise<MaskResult> | undefined = undefined;
      if (hasMask) {
        maskGet = get(`projects/${projectId}/labels/${imageId}/mask`, false, false, {
          responseType: 'blob',
        });
      }

      const imageResult = await imageGet;
      let maskResult: MaskResult | undefined = undefined;
      if (maskGet) {
        maskResult = await maskGet;
      }

      if (!imageResult.data || imageResult.status < 200 || imageResult.status > 299) {
        setImageExist(false);
        setLoading(false);
        return;
      }
      setImageExist(true);
      let base64 = await blobToBase64(imageResult.data);
      setStepFromBlob(imageResult.data);

      document.body.dispatchEvent(
        new CustomEvent('image-selected', {
          detail: {
            src: base64,
            title: `Image - ${imageId}`,
          },
          bubbles: true,
        })
      );

      if (!maskResult?.data || maskResult.status < 200 || maskResult.status > 299) {
        setLoading(false);
        return;
      }
      base64 = await blobToBase64(maskResult.data);

      document.body.dispatchEvent(
        new CustomEvent('maskimported', {
          detail: {
            imageData: base64,
            title: 'Image',
          },
          bubbles: true,
        })
      );

      setLoading(false);
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, imageId, selectedProject?.projectUseCase?.modelClasses]);

  const { tool, shape } = mapTools(tools);
  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('toolchanged', { bubbles: true, detail: { tool, dim: tools.pencilWidth, shape } })
    );
  }, [tool, tools.pencilWidth, shape]);

  useEffect(() => {
    const alpha = (100 - tools.transparency) / 100;
    document.dispatchEvent(new CustomEvent('maskalphachanged', { bubbles: true, detail: { alpha } }));
  }, [tools.transparency]);

  const canvas = useMemo(
    () => (
      <canvas-image-layer>
        <mask-component></mask-component>
        <scissors-component></scissors-component>
        <polygon-tool-component></polygon-tool-component>
      </canvas-image-layer>
    ),
    []
  );

  return (
    <ImageContent>
      {canvas}
      <NoContentBackdrop show={!loading && !imageExist} />
      <BackdropLoading loading={loading} label='' />
      <CanvasZoom />
    </ImageContent>
  );
}
const ImageContent = styled.div`
  margin: 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  position: relative;
`;

export default CanvasContainer;
