// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImageEvaluationMessage } from '@tls/sw91-communication/types/com.api_predictor';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';

export interface ZoomLevel {
  min: number;
  max: number;
}

export interface EvaluationState {
  hoveredMd5s?: string[];
  selectedVersions?: ProjectVersion[];
  zoomLevel?: ZoomLevel;
  activeMd5?: string;
  cacheProjectId?: string;
  evaluationCache: { [key: string]: ImageEvaluationMessage[] };
  evaluatedVersions: ProjectVersion[];
  index: number;
  length: number;
}

const initialState: EvaluationState = {
  evaluationCache: {} as { [key: string]: ImageEvaluationMessage[] },
  evaluatedVersions: [],
  index: -1,
  length: 0,
};

export const projectImageSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    updateIndex: (state, action: PayloadAction<number>) => {
      let index = action.payload;
      if (index < 0) index = 0;
      else if (index >= state.length) {
        index = state.length - 1;
      }
      state.index = index;
    },
    setHoveredMd5s: (state, action: PayloadAction<string[]>) => {
      state.hoveredMd5s = action.payload;
    },
    setSelectedVersions: (state, action: PayloadAction<ProjectVersion[]>) => {
      state.selectedVersions = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<ZoomLevel>) => {
      state.zoomLevel = action.payload;
    },
    setActiveMd5: (state, action: PayloadAction<string | undefined>) => {
      state.activeMd5 = action.payload;
    },
    setEvaluationCache: (
      state,
      action: PayloadAction<{ projectId: string; strategyVersion: ProjectVersion; data: ImageEvaluationMessage[] }>
    ) => {
      const key = action?.payload?.strategyVersion;
      if (action?.payload?.projectId !== state.cacheProjectId) {
        state.evaluationCache = {};
      }
      state.cacheProjectId = action.payload?.projectId;
      if (key) {
        state.evaluationCache[key] = action.payload?.data;
        state.length = state.evaluationCache[key].length;
      }
    },
    clearEvaluationProgressCache: state => {
      state.evaluatedVersions = [];
      state.evaluationCache = {};
      state.index = -1;
      state.length = 0;
      state.cacheProjectId = undefined;
    },
    setEvaluatedVersions: (state, action: PayloadAction<ProjectVersion[]>) => {
      state.evaluatedVersions = action.payload;
    },
  },
});

export const {
  setHoveredMd5s,
  setSelectedVersions,
  setZoomLevel,
  setActiveMd5,
  setEvaluationCache,
  clearEvaluationProgressCache,
  setEvaluatedVersions,
  updateIndex,
} = projectImageSlice.actions;
export default projectImageSlice.reducer;
