// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ToolSelection from './tools/Selection';
import ToolMode from './tools/Mode';
import ToolSettings from './tools/Settings';
import { Molecules } from '@tls/treact-ui';

export default function LabelTools() {
  const { t } = useTranslation();
  return (
    <Container>
      <Molecules.Collapsible defaultOpen label={<BoldLabel>{t('project.label.tools.title')}</BoldLabel>}>
        <ToolSelection />
        <ToolMode />
        <ToolSettings />
      </Molecules.Collapsible>
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  height: calc(100% - 1rem);
`;
const BoldLabel = styled.span`
  font-weight: bold;
`;
