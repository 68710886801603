// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { FLAGS, Organisms } from '@tls/treact-ui';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CreateProjectDialogContent from './settings/CreateProjectDialogContent';
import { toggleCreateDialog } from 'store/reducers/projectSettingsSlice';
import { useTranslation } from 'react-i18next';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { UseCase } from '@tls/sw91-communication/types/com.base';
import useAppPermissions from 'hooks/useAppPermissions';
import NoLicenseDialogContent from './settings/NoLicenseDialogContent';

const { Dialog } = Organisms;
const ErrorFlag = FLAGS.COMPONENTS.error;

enum Page {
  componentDetection,
  qualityControl,
}

export default function CreateProjectDialog() {
  const { id, show, duplicateSourceId } = useAppSelector(s => s.projectSettings);
  const { selectedProject } = useProjectsMetadata(id ?? duplicateSourceId);
  const [page, setPage] = useState(Page.componentDetection);
  const [useCase, setUseCase] = useState<UseCase>(UseCase.COMPONENT_DETECTION);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { hasCreateCDProjectPermission, hasCreateQCProjectPermission } = useAppPermissions();

  useEffect(() => {
    if (selectedProject) {
      const newPage =
        selectedProject.projectUseCase?.useCase === UseCase.COMPONENT_DETECTION
          ? Page.componentDetection
          : Page.qualityControl;

      if (newPage !== page) {
        setPage(newPage);
      }
    }

    if (id) {
      const newUseCase = selectedProject?.projectUseCase?.useCase ?? UseCase.COMPONENT_DETECTION;
      if (newUseCase !== useCase) {
        setUseCase(newUseCase);
      }
    }
  }, [selectedProject, id, useCase, page]);

  const onTabClick = useCallback((newPage: Page) => () => setPage(newPage), []);

  const onClose = useCallback(() => {
    dispatch(toggleCreateDialog());
  }, [dispatch]);

  const getHeaderLabel = useCallback(
    (baseLabel: string) => {
      if (id) return 'project.settings.edit.title';
      if (duplicateSourceId) return 'project.settings.duplicate.title';
      return baseLabel;
    },
    [id, duplicateSourceId]
  );

  const header = useMemo(() => {
    const subMenus: ReactElement[] = [];
    if (hasCreateCDProjectPermission) {
      subMenus.push(
        <Organisms.SubMenuHorizontal.SubMenuTab
          label={t(getHeaderLabel('project.settings.dialog.componentDetection.title'))}
          selected={page === Page.componentDetection}
          onClick={onTabClick(Page.componentDetection)}
          key='CD'
        />
      );
    }
    if (hasCreateQCProjectPermission) {
      subMenus.push(
        <Organisms.SubMenuHorizontal.SubMenuTab
          label={t(getHeaderLabel('project.settings.dialog.qualityControl.title'))}
          selected={page === Page.qualityControl}
          onClick={onTabClick(Page.qualityControl)}
          key='QC'
          icon={hasCreateQCProjectPermission ? null : <ErrorFlag width={'1.75rem'} />}
        />
      );
    }
    if (subMenus.length > 1) {
      return <Organisms.SubMenuHorizontal>{subMenus}</Organisms.SubMenuHorizontal>;
    }
    return null;
  }, [page, onTabClick, t, getHeaderLabel, hasCreateCDProjectPermission, hasCreateQCProjectPermission]);

  const body = useMemo(() => {
    if ((hasCreateCDProjectPermission && hasCreateQCProjectPermission) || id || duplicateSourceId) {
      return <CreateProjectDialogContent page={page} saveOnEnter={show} />;
    } else if (hasCreateCDProjectPermission && !hasCreateQCProjectPermission) {
      return <CreateProjectDialogContent page={Page.componentDetection} saveOnEnter={show} />;
    } else if (!hasCreateCDProjectPermission && hasCreateQCProjectPermission) {
      return <CreateProjectDialogContent page={Page.qualityControl} saveOnEnter={show} />;
    } else {
      return <NoLicenseDialogContent />;
    }
  }, [hasCreateCDProjectPermission, hasCreateQCProjectPermission, show, page, id, duplicateSourceId]);

  return (
    <Dialog enableClosing open={show} size='auto' onClose={onClose}>
      {!id && !duplicateSourceId ? (
        header
      ) : (
        <h2 style={{ padding: '0.8rem', marginLeft: '0.5rem' }}>{t(getHeaderLabel(''))}</h2>
      )}
      {body}
    </Dialog>
  );
}
