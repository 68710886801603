// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { AppPermission } from 'model/AppPermission';
import { useAppSelector } from 'store/hooks';

function useContract(appPermission: AppPermission) {
  const { loading, contract, hide } = useAppSelector(s => s.license);

  return { loading, contract: contract?.permissions?.[appPermission], hide: hide?.[appPermission] };
}

export default useContract;
