// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProjectSettingsState {
  id?: string;
  duplicateSourceId?: string;
  show: boolean;
  importDialog: boolean;
}

const initialState: ProjectSettingsState = {
  id: undefined,
  show: false,
  importDialog: false,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    toggleCreateDialog: state => {
      state.show = !state.show;
      if (!state.show) {
        state.id = undefined;
        state.duplicateSourceId = undefined;
      }
    },
    toggleImportDialog: state => {
      state.importDialog = !state.importDialog;
      if (!state.importDialog) {
        state.id = undefined;
        state.duplicateSourceId = undefined;
      }
    },
    setEditId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
      state.duplicateSourceId = undefined;
      state.show = true;
    },
    setIdToDuplicateFrom: (state, action: PayloadAction<string | undefined>) => {
      state.id = undefined;
      state.duplicateSourceId = action.payload;
      state.show = true;
    },
  },
});

export const { toggleCreateDialog, toggleImportDialog, setEditId, setIdToDuplicateFrom } = projectsSlice.actions;
export default projectsSlice.reducer;
