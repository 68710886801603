// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useEffect, useState } from 'react';
import { UseCase } from '@tls/sw91-communication/types/com.base';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import useProjectStrategy from 'hooks/projectSettingsHooks/useProjectStrategy';
import useProjectLabelManagement from 'hooks/projectSettingsHooks/useProjectLabelManagement';
import { useProjectTagManagement } from 'hooks/projectSettingsHooks/useProjectTagManagement';
import { ProjectSettingsProps } from '../../projectSelection/common/ProjectSettingTypes';
import { colorOptions, getColorOption } from 'components/common/ColorOptions';
import { StrategySelector } from './subcomponents/StrategySelector';
import { ProjectNameInput } from './subcomponents/ProjectNameInput';
import { TagInput } from './subcomponents/TagInput';
import { UseCaseAdditions } from './subcomponents/useCaseAdditions';
import { Atoms, FLAGS } from '@tls/treact-ui';
import LabelInput from './subcomponents/LabelInput';
import useAppPermissions from 'hooks/useAppPermissions';

interface ProjectSettingsFormProps extends ProjectSettingsProps {
  useCase: UseCase;
}
const DisconnectedFlag = FLAGS.COMPONENTS.disconnected;

export default function ProjectSettingsForm({ onChange, containerRef, useCase }: Readonly<ProjectSettingsFormProps>) {
  const { id, duplicateSourceId } = useAppSelector(s => s.projectSettings);
  const { t } = useTranslation();

  const { selectedProject, allTags } = useProjectsMetadata(id ?? duplicateSourceId);
  const isEditing = Boolean(id ?? duplicateSourceId);

  const [projectName, setProjectName] = useState<string>('');
  const [projectUseCase, setProjectUseCase] = useState<UseCase>(useCase);
  const { hasCreateCDProjectPermission, hasCreateQCProjectPermission } = useAppPermissions();

  const { tags, setTags, selectedTag, allTagsAsObject, setSelectedTag, addSelectedTag, removeTag } =
    useProjectTagManagement(selectedProject?.tags ?? [], allTags);

  const { labelName, labelsInfo, addLabel, updateLabel, removeLabel, setLabelsInfo, availableColorOptions } =
    useProjectLabelManagement();

  const { projectStrategy, setProjectStrategy } = useProjectStrategy(selectedProject, useCase);

  useEffect(() => {
    setProjectName(selectedProject?.projectName ?? '');
    setTags(selectedProject?.tags ?? []);
    setProjectUseCase(selectedProject?.projectUseCase?.useCase ?? useCase);
    setLabelsInfo(
      selectedProject?.projectUseCase?.modelClasses?.map(label => ({
        labelName: label.className ?? '',
        labelColor: getColorOption(label.labelColor),
        labelCategory: '',
      })) ?? []
    );
  }, [selectedProject, useCase, setTags, setLabelsInfo]);

  useEffect(() => {
    if (labelsInfo.length === 0) {
      setLabelsInfo([{ labelName: labelName, labelColor: colorOptions[0], labelCategory: '' }]);
    } else setLabelsInfo(labelsInfo);
  }, [labelsInfo, setLabelsInfo, labelName]);

  useEffect(() => {
    onChange({
      labelsInfo,
      projectName,
      tags,
      strategy: projectStrategy.id,
      useCase: projectUseCase,
    });
  }, [labelsInfo, onChange, projectName, tags, projectStrategy, projectUseCase]);

  const noLicence =
    (useCase === UseCase.QUALITY_CHECK && !hasCreateQCProjectPermission) ||
    (useCase === UseCase.COMPONENT_DETECTION && !hasCreateCDProjectPermission);
  const flag = <DisconnectedFlag width={'1.75rem'} />;

  return (
    <>
      {noLicence ? (
        <Content>
          {flag}
          <Atoms.Headline
            label={
              useCase === UseCase.QUALITY_CHECK ? t('license.qc.noLicense.title') : t('license.cd.noLicense.title')
            }
          />
          <span>
            {useCase === UseCase.QUALITY_CHECK ? t('license.qc.noLicense.details') : t('license.cd.noLicense.details')}
          </span>
        </Content>
      ) : (
        <Container>
          <ProjectNameInput
            projectName={projectName}
            setProjectName={setProjectName}
            isEditing={isEditing}
            useCase={projectUseCase}
          />

          <ModelSelection className='match-header'>
            {projectUseCase === UseCase.COMPONENT_DETECTION && (
              <StrategySelector
                lockSelection={isEditing}
                projectStrategy={projectStrategy}
                setProjectStrategy={setProjectStrategy}
                usecase={projectUseCase}
              />
            )}
            <UseCaseAdditions useCase={projectUseCase} isEditing={isEditing} />
          </ModelSelection>
          <LabelInput
            isEditing={isEditing}
            labelsInfo={labelsInfo}
            addLabel={addLabel}
            removeLabel={removeLabel}
            updateLabel={updateLabel}
            useCase={projectUseCase}
            containerRef={containerRef}
            availableColorOptions={availableColorOptions}
          />
          <TagInput
            tags={tags}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            addSelectedTag={addSelectedTag}
            removeTag={removeTag}
            allTagsAsObject={allTagsAsObject}
            containerRef={containerRef}
            useCase={projectUseCase}
            t={t}
          />
        </Container>
      )}
    </>
  );
}

// Styled Components
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 850px;
  min-width: 600px;
  max-height: calc(100vh - 180px);

  .left-divider {
    text-align: left;
  }

  .half-pager {
    width: 50%;
  }

  .match-header {
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
  }

  .push-right {
    margin-left: 0.5rem;
  }
`;

const Content = styled.div`
  display: grid;
  place-items: center;
  margin: auto;
`;

const ModelSelection = styled.div`
  display: grid;
  grid-template-columns: 2fr;
  gap: 0.5rem;
`;
