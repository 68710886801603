// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ModelClassMessage } from '@tls/sw91-communication/types/com.base';
import { getColorOption } from 'components/common/ColorOptions';
import { AnimationCss } from 'components/treactui-template/atoms/animation/AnimatePulse';
import loadImageIntoElementByUrl from 'components/treactui-template/meta/loadImageIntoElement';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { AssignLabelColor } from 'model/ImageData';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ZoomControls from './ZoomControls';
export type Props = {
  projectId: string;
  imageId: string;
  version: string;
};

export default function ImagePreview({ projectId, imageId, version }: Props) {
  const [loading, setLoading] = useState(true);
  const imgCanvasRef = useRef<HTMLCanvasElement>(null);
  const maskCanvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastDrawingRef = useRef<string | null>(null);

  const { selectedProject } = useProjectsMetadata(projectId);

  const colorArray = selectedProject?.projectUseCase?.modelClasses?.map(m => getColorOption(m.labelColor).id).join(',');

  const colors = useMemo(() => {
    return selectedProject?.projectUseCase?.modelClasses ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorArray]);

  useEffect(() => {
    drawImage(imgCanvasRef, projectId, imageId, setLoading, lastDrawingRef);
  }, [imageId, projectId]);

  useEffect(() => {
    lastDrawingRef.current = imageId;
    drawMask(maskCanvasRef, colors, { projectId, imageMd5: imageId, version }, setLoading, lastDrawingRef);
  }, [imageId, projectId, version, colors]);

  return (
    <Container ref={containerRef} loading={loading.toString()}>
      <Canvas id={`image-${imageId}`} ref={imgCanvasRef} />
      <Canvas id={`prediction-${imageId}`} ref={maskCanvasRef} />
      <ZoomControls imgCanvasRef={imgCanvasRef} maskCanvasRef={maskCanvasRef} parentRef={containerRef} />
    </Container>
  );
}

function drawImage(
  canvas: RefObject<HTMLCanvasElement>,
  projectId: string,
  imageMd5: string,
  setLoading: (loading: boolean) => void,
  currProcedIdRef: RefObject<string | null>
) {
  const context = canvas.current?.getContext('2d');
  if (!canvas.current || !context) return;

  setLoading(true);
  context.clearRect(0, 0, canvas.current.width, canvas.current.height);

  const background = new Image();

  background.onload = function () {
    if (currProcedIdRef?.current !== imageMd5) {
      console.info('skipping to load image, because id is obsulete', imageMd5);
      return;
    }
    if (canvas.current) {
      canvas.current.height = background.height;
      canvas.current.width = background.width;
    }
    context?.drawImage(background, 0, 0);
    setLoading(false);
  };
  void loadImageIntoElementByUrl(`projects/${projectId}/labels/${imageMd5}/image`, background);
}
interface MaskProps {
  projectId: string;
  imageMd5: string;
  version: string;
}
function drawMask(
  canvas: RefObject<HTMLCanvasElement>,
  colors: ModelClassMessage[],
  maskProps: MaskProps,
  setLoading: (loading: boolean) => void,
  currProcedIdRef: RefObject<string | null>,
  tryCount: number = 1
) {
  const { projectId, imageMd5, version } = maskProps;
  if (colors.length < 1) return;
  setLoading(true);
  const prediction = new Image();

  const predictContext = canvas.current?.getContext('2d', { willReadFrequently: true });
  if (!canvas.current || !predictContext) return;

  predictContext.clearRect(0, 0, canvas.current.width, canvas.current.height);

  prediction.onerror = () => {
    console.info(`error to load prediction on ${tryCount} try for id: ${imageMd5}. Trying again...`);
    if (tryCount > 3) {
      setLoading(false);
      return;
    }
    drawMask(canvas, colors, maskProps, setLoading, currProcedIdRef, tryCount + 1);
  };
  prediction.onload = function () {
    if (currProcedIdRef?.current !== imageMd5) {
      console.info('skipping to load prediction, because id is obsulete', imageMd5);
      return;
    }
    if (!canvas.current) return;
    canvas.current.height = prediction.height;
    canvas.current.width = prediction.width;

    predictContext?.drawImage(prediction, 0, 0);
    const data = predictContext.getImageData(0, 0, canvas.current?.width, canvas.current?.height);

    AssignLabelColor(data, colors);

    predictContext.putImageData(data, 0, 0);
    setLoading(false);
  };

  void loadImageIntoElementByUrl(`projects/${projectId}/predict/${version}/${imageMd5}`, prediction);
}

const Container = styled.div<{ loading: string }>`
  position: relative;
  ${({ loading }) => loading === 'true' && AnimationCss}
  overflow: hidden;
`;

const Canvas = styled.canvas`
  position: absolute;
  inset: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  object-fit: contain;
`;
