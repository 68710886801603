// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { CreateProjectAckMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { StrategyType, AcknowledgeStatus, UseCase } from '@tls/sw91-communication/types/com.base';
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import { IdToColor } from 'components/common/ColorOptions';
import ProjectSettingsForm from 'components/project/settings/ProjectSettingsForm';
import { ProjectSettings } from 'components/projectSelection/common/ProjectSettingTypes';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import useProjectDetailsNavigation from 'hooks/useProjectDetailsNavigation';
import useProjectSelectionApi from 'hooks/useProjectSelectionApi';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleCreateDialog } from 'store/reducers/projectSettingsSlice';
import { fetchProjects } from 'store/reducers/projectsSlice';
import { AppRoutes } from 'utilities/router/AppRoutes';

const { Dialog } = Organisms;

enum Page {
  componentDetection,
  qualityControl,
}

type Props = {
  page: Page;
  saveOnEnter?: boolean;
};

export default function CreateProjectDialogContent({ page, saveOnEnter = false }: Readonly<Props>) {
  const { id, duplicateSourceId } = useAppSelector(s => s.projectSettings);

  const { t } = useTranslation();

  const [newSettings, setNewSettings] = useState<ProjectSettings>({
    labelsInfo: [],
    projectName: '',
    tags: [],
    strategy: StrategyType.SIMPLE_STRATEGY,
    useCase: UseCase.COMPONENT_DETECTION,
  });

  const onChange = useCallback((newSettings: ProjectSettings) => {
    setNewSettings(newSettings);
  }, []);

  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleCreateDialog());
  }, [dispatch]);

  // Setup dialog header label
  let headerLabel = 'project.settings.edit.title';
  if (duplicateSourceId) headerLabel = 'project.settings.duplicate.title';

  const containerRef = useRef<HTMLDivElement>(null);

  const { loading, save, edit, duplicate } = useProjectSelectionApi();

  const { navigateToDetails } = useProjectDetailsNavigation();

  const onSave = useCallback(async () => {
    const { labelsInfo, projectName, tags, strategy, useCase } = newSettings;
    const labels = labelsInfo.map((label, index) => ({
      classIndex: index,
      className: label.labelName,
      labelColor: IdToColor(label.labelColor.id),
      labelCategory: label.labelCategory,
      labelGrayScale: 0,
    }));

    let status: number;
    let errorKey = 'project.settings.add.error';
    let successKey = 'project.settings.add.success';
    if (!id && !duplicateSourceId) {
      const saveResult = await save({
        name: projectName,
        labels: labels,
        tags: tags,
        strategy: strategy,
        useCase: useCase,
      });
      status = saveResult.status;

      const result = CreateProjectAckMessage.fromJSON(saveResult.data);
      if (result.messageHeader?.projectId && result.status?.status === AcknowledgeStatus.SUCCESS)
        navigateToDetails(
          AppRoutes.ProjectLabelFullPath(result.messageHeader.projectId),
          result.messageHeader.projectId
        );
    } else if (duplicateSourceId) {
      const editResult = await duplicate({
        name: projectName,
        id: duplicateSourceId,
        tags: tags,
        strategy: strategy,
        labels: labels,
        useCase: useCase,
      });

      status = editResult.status;
      errorKey = 'project.settings.duplicate.error';
      successKey = 'project.settings.duplicate.success';
    } else if (id) {
      const editResult = await edit({
        name: projectName,
        id: id,
        tags: tags,
        strategy: strategy,
        labels: labels,
        useCase: useCase,
      });

      status = editResult.status;
      errorKey = 'project.settings.edit.error';
      successKey = 'project.settings.edit.success';
    } else {
      status = 404;
    }

    dispatch(fetchProjects());

    ToastByStatus(status, t(headerLabel), t(errorKey, { name: projectName }), t(successKey, { name: projectName }));

    if (status < 200 || status >= 400) return;
    dispatch(toggleCreateDialog());
  }, [dispatch, duplicate, duplicateSourceId, edit, headerLabel, id, navigateToDetails, newSettings, save, t]);

  const handleEnterPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'Enter') {
        onSave();
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (!saveOnEnter) return;
    window.addEventListener('keydown', handleEnterPress);

    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [saveOnEnter, handleEnterPress]);

  const { IconLoading } = ICONS;
  let saveIcon: React.JSX.Element | undefined = <IconLoading />;
  if (!loading) {
    saveIcon = undefined;
  }

  return (
    <>
      <Dialog.DialogContent ref={containerRef}>
        {page === Page.componentDetection || page === Page.qualityControl ? (
          <ProjectSettingsForm
            onChange={onChange}
            containerRef={containerRef}
            useCase={page === Page.componentDetection ? UseCase.COMPONENT_DETECTION : UseCase.QUALITY_CHECK}
          />
        ) : null}
      </Dialog.DialogContent>
      <Dialog.Footer
        right={[
          <Atoms.FooterButton key='cancel' label={t('cancel')} onClick={onClose} variant='secondary' />,
          <Atoms.FooterButton
            icon={saveIcon}
            key='save'
            label={t('save')}
            disabled={newSettings.projectName.trim().length < 1 || newSettings.labelsInfo.length < 1 || loading}
            onClick={onSave}
          />,
        ]}
      />
    </>
  );
}
