// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { StrategyType, UseCase } from '@tls/sw91-communication/types/com.base';
import { useEffect, useState } from 'react';

export const getStrategyOptions = (useCase: UseCase) => {
  console.log('useCase', useCase);
  // Usecase might be used in the future for more strategy options
  return [
    { id: StrategyType.SIMPLE_STRATEGY, labelKey: 'highSpeed' },
    { id: StrategyType.TWO_STEP_STRATEGY, labelKey: 'highPrecision' },
  ];
};

export default function useProjectStrategy(selectedProject, useCase: UseCase) {
  const [projectStrategy, setProjectStrategy] = useState(getStrategyOptions(useCase)[0]);

  useEffect(() => {
    setProjectStrategy(
      getStrategyOptions(useCase).find(s => s.id === selectedProject?.strategyType) ?? getStrategyOptions(useCase)[0]
    );
  }, [selectedProject, useCase]);

  return { projectStrategy, setProjectStrategy };
}
