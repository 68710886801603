// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { get } from '@tls/react-saf-ui';
import { Contract, parseContract } from 'model/Contract';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export default function useUserContractsApi(userId: string) {
  const { data, isLoading, isError } = useQuery({
    queryKey: [`contracts/user/${userId}`],
    queryFn: async () => (userId ? await get(`contracts/user/${userId}`, false, true) : undefined),
  });

  const parsedData: Contract[] | undefined = useMemo(
    () =>
      (Array.isArray(data?.data) ? data?.data : [])?.reduce((pv: Contract[], cv) => {
        const entry = parseContract(cv);
        if (!entry) return pv; // skip invalid entries
        if (
          !pv.find(
            e =>
              e.contractId === entry.contractId &&
              e.customerNumber === entry.customerNumber &&
              e.endCustomerNumber === entry.endCustomerNumber
          )
        ) {
          pv.push(entry);
        }
        return pv;
      }, []),
    [data?.data]
  );

  return {
    data: parsedData,
    isLoading,
    isError,
  };
}
