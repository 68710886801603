// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';

export default function HighSpeedImage({ width, height, id, viewBox, ...other }: Readonly<IconProps>) {
  return (
    <svg
      id={id ?? 'svg-icon-high-speed'}
      width={width ?? 120}
      height={height ?? 96}
      viewBox={viewBox ?? '0 0 120 96'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <rect width='120' height='96' fill='url(#paint0_linear_1187_35809)' />
      <rect x='16.5' y='13.5' width='88' height='61' fill='#E6E6E6' stroke='#A1A1A1' />
      <path d='M49 15H102V52H49V15Z' fill='#285172' />
      <defs>
        <linearGradient
          id='paint0_linear_1187_35809'
          x1='108.402'
          y1='20.83'
          x2='73.546'
          y2='85.9686'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0.05' />
          <stop offset='1' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
}
