// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { UseCase } from '@tls/sw91-communication/types/com.base';
import { Atoms, ICONS, Molecules } from '@tls/treact-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colorIconSelector, colorKeySelector, colorLabelSelector } from 'components/common/ColorOptions';
import { LabelInputProps } from 'components/projectSelection/common/ProjectSettingTypes';
import useAppPermissions from 'hooks/useAppPermissions';

const { IconPlus } = ICONS;

const LabelInput = ({
  useCase,
  containerRef,
  labelsInfo,
  addLabel,
  updateLabel,
  removeLabel,
  availableColorOptions,
  isEditing = false,
}: Readonly<LabelInputProps>) => {
  const { t } = useTranslation();

  const { hasMultiLabelCDPermision } = useAppPermissions();

  const showMultilabelEdits =
    !isEditing &&
    (useCase === UseCase.QUALITY_CHECK || (useCase === UseCase.COMPONENT_DETECTION && hasMultiLabelCDPermision));

  return (
    <>
      {labelsInfo.map((label, index) => (
        <InputColumns
          key={label.labelColor.id}
          className='match-header'
          withoutDelete={useCase === UseCase.COMPONENT_DETECTION && !hasMultiLabelCDPermision}
        >
          <Atoms.InputFieldText
            flag='mandatory'
            label={t('project.settings.label.name')}
            value={label.labelName}
            onValueChange={v => updateLabel(index, { ...label, labelName: v })}
            asAtom
          />
          <Molecules.DropdownContent
            label={t('project.settings.label.color')}
            className='no-shadow'
            options={availableColorOptions}
            selectedOption={label.labelColor}
            keySelector={colorKeySelector}
            labelSelector={colorLabelSelector(t)}
            iconSelector={colorIconSelector}
            onChange={v => updateLabel(index, { ...label, labelColor: v })}
            containerRef={containerRef}
            collapse
          />
          {showMultilabelEdits && (
            <DeleteButton>
              <Atoms.Button
                as='button'
                iconKey='IconDelete'
                onClick={() => removeLabel(index)}
                variant='secondary'
                disabled={labelsInfo?.length <= 1}
              />
            </DeleteButton>
          )}
        </InputColumns>
      ))}
      {showMultilabelEdits && (
        <RightButton>
          <Atoms.Button
            variant='secondary'
            icon={<IconPlus />}
            label={t('project.settings.label.add')}
            onClick={addLabel}
            disabled={labelsInfo.length >= 5}
          />
        </RightButton>
      )}
    </>
  );
};

LabelInput.displayName = 'LabelInput';

const InputColumns = styled.div<{ withoutDelete: boolean }>`
  display: flex;
  grid-gap: 8px;
`;

const DeleteButton = styled.div`
  padding-top: 1.5rem;
  display: grid;
  place-items: end;
`;

const RightButton = styled.div`
  display: grid;
  grid-gap: 8px;
  place-items: end;
  margin-right: 0.5rem;
`;
export default LabelInput;
