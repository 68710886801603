// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { get } from '@tls/react-saf-ui';
import { useDispatch } from 'react-redux';
import { setLicenseContract } from 'store/reducers/licenseSlice';
import { parseContractOverview } from 'model/ContractOverview';

export default function ContractFetcher() {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ['active-licenses'],
    queryFn: async () => await get('contracts', false, true),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data?.data || !isFetched) return;

    try {
      const result = parseContractOverview(data.data);
      if (!result) return;

      dispatch(setLicenseContract(result));
    } catch (er) {
      console.error(er);
      setTimeout(() => refetch(), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, data?.data, dispatch]);

  return <></>;
}
