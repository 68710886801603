// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';
import useUploadApi from 'hooks/useUploadApi';
import { changeSelectedImage } from 'store/reducers/projectImageSlice';
import useProjectLabels from 'hooks/useProjectLabels';
import GetOS, { OS } from 'components/common/GetOs';
import { exportMask, redoMask, undoMask } from './canvas/DrawHandlerExtension';
import { ImageListOrderContext } from './images/ImageListOrderContext';
import { ImagesContext } from '../images/ImagesContext';
import { clearEvaluationProgressCache } from 'store/reducers/projectEvaluationSlice';
import useAppPermissions from 'hooks/useAppPermissions';

export default function ImageFooter() {
  const { t } = useTranslation();
  const { id } = useParams();

  const { labels = [], selectedMd5 } = useProjectLabels();
  const dispatch = useAppDispatch();
  const artifactDetection = useAppSelector(s => s.tools.artifactDetection);
  const { hasPermissionForProject } = useAppPermissions();

  const [loading, setLoading] = useState<'cancel' | 'save'>();

  const { uploadMask } = useUploadApi(id ?? '');

  const reset = useCallback(async () => {
    if (!selectedMd5) return;
    document.body.dispatchEvent(new CustomEvent('resetmaskhistory', { bubbles: true }));
  }, [selectedMd5]);

  const { currentOrder } = useContext(ImageListOrderContext);

  const { editImage } = useContext(ImagesContext);

  const save = useCallback(async () => {
    if (!!loading || !selectedMd5 || !hasPermissionForProject) return;
    dispatch(clearEvaluationProgressCache());
    setLoading('cancel');
    const file = await exportMask();
    if (!file) {
      setLoading(undefined);
      return;
    }

    await uploadMask(file, selectedMd5 ?? '');
    editImage({ imageMd5: selectedMd5, hasMask: true });
    if (!labels) {
      setLoading(undefined);
      return;
    }

    const handleImageSave = e => {
      if (e.detail.canSave) {
        const idx = currentOrder?.current.findIndex(l => l === selectedMd5);
        if (idx !== undefined && idx + 1 < (currentOrder?.current.length ?? 0)) {
          dispatch(changeSelectedImage(currentOrder?.current[idx + 1]));
        }

        setLoading('cancel');
      }
      setLoading(undefined);
      document.removeEventListener('saveImage', handleImageSave);
    };

    document.addEventListener('saveImage', handleImageSave);

    document.dispatchEvent(
      new CustomEvent('detectarifacts', { bubbles: true, detail: { detectArtifact: artifactDetection } })
    );
  }, [
    artifactDetection,
    currentOrder,
    dispatch,
    labels,
    loading,
    selectedMd5,
    uploadMask,
    editImage,
    hasPermissionForProject,
  ]);

  const handleButtonPress = useCallback(
    (event: KeyboardEvent) => {
      const { key, metaKey, ctrlKey } = event;

      const os = GetOS();
      const IsCtrlPressed = os === OS.MacOs ? metaKey : ctrlKey;
      if (key.toLowerCase() === 's' && IsCtrlPressed) {
        save();
        event.preventDefault();
      } else if (key.toLowerCase() === 'r' && IsCtrlPressed) {
        reset();
        event.preventDefault();
      }
    },
    [reset, save]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress);

    return () => {
      window.removeEventListener('keydown', handleButtonPress);
    };
  }, [handleButtonPress]);

  const { IconLoading, IconUndo } = ICONS;

  return (
    <Organisms.Footer
      left={[
        <Atoms.FooterButton key='undo' icon={<IconUndo />} variant='secondary' onClick={undoMask} />,
        <Atoms.FooterButton key='redo' icon={<IconUndo className='mirror' />} variant='secondary' onClick={redoMask} />,
      ]}
      right={[
        <Atoms.FooterButton
          key='cancel'
          label={t('cancel')}
          variant='secondary'
          icon={loading === 'cancel' ? <IconLoading /> : undefined}
          onClick={reset}
          disabled={!!loading || !selectedMd5}
        />,
        <Atoms.FooterButton
          key='redo'
          icon={loading === 'save' ? <IconLoading /> : undefined}
          label={t('saveAndNext')}
          onClick={save}
          disabled={!!loading || !selectedMd5 || !hasPermissionForProject}
        />,
      ]}
    />
  );
}
