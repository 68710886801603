// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import type { IconProps } from '@tls/treact-ui/lib/types/components/Icons/types';
import React from 'react';

const IconUnlocked = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-unlocked'}
      data-name='svg-unlocked'
      data-testid='svg-unlocked'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 25 37'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.248 28.513L14 28.659V33H13.936L13.791 33.248C13.517 33.719 13.034 34 12.5 34C11.966 34 11.483 33.719 11.208 33.248L11.064 33H11V28.659L10.751 28.514C9.655 27.875 9 26.748 9 25.5C9 23.571 10.57 22 12.5 22C14.43 22 16 23.571 16 25.5C16 26.748 15.346 27.875 14.248 28.513ZM7 20V8.5C7 5.468 9.467 3 12.5 3C15.532 3 18 5.468 18 8.5V13H21V8.5C21 3.813 17.186 0 12.5 0C7.813 0 4 3.813 4 8.5V20H0V37H25V20H7Z'
        fill={fill ?? 'currentColor'}
      />
    </svg>
  );
};

export default IconUnlocked;
