// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { Molecules } from '@tls/treact-ui';
import { colorIconSelector, ColorOptionType, getColorOption, IdToColor } from 'components/common/ColorOptions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ParamsWithId from 'model/ParamsWithId';
import { useParams } from 'react-router-dom';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import GetOS, { OS } from 'components/common/GetOs';

export default function LabelSelection() {
  const { t } = useTranslation();
  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);

  const modelClasses = selectedProject?.projectUseCase?.modelClasses;
  const colorOptions = useMemo(() => {
    return modelClasses?.map(modelClass => getColorOption(modelClass.labelColor)) ?? [];
  }, [modelClasses]);

  const [color, setColor] = useState<ColorOptionType>(() => colorOptions[0]);
  const labelsArray = useMemo(() => colorOptions.map(color => IdToColor(color.id)), [colorOptions]);

  useEffect(() => {
    if (colorOptions.length > 0) {
      setColor(colorOptions[0]);
      document.dispatchEvent(new CustomEvent('labelindexchanged', { bubbles: true, detail: { idx: 0 } }));
    }
  }, [colorOptions]);

  const labelInfo = useCallback(
    (colorId: string) => {
      const colorString = IdToColor(colorId);
      const matchingClass = modelClasses?.find(modelClass => {
        const labelColor = modelClass.labelColor;
        return (
          labelColor?.red === colorString.red &&
          labelColor?.green === colorString.green &&
          labelColor?.blue === colorString.blue
        );
      });
      return {
        labelName: matchingClass?.className,
        labelCategory: /*matchingClass?.labelCategory ?? */ 'Label Category',
      };
    },
    [modelClasses]
  );

  const handleColorChange = useCallback(
    (selectedColor: ColorOptionType) => {
      setColor(selectedColor);
      document.dispatchEvent(
        new CustomEvent('labelindexchanged', { bubbles: true, detail: { idx: colorOptions.indexOf(selectedColor) } })
      );
    },
    [colorOptions]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const colorIndex = colorOptions.indexOf(color);
      const { key, metaKey, ctrlKey } = event;

      const os = GetOS();
      const IsCtrlPressed = os === OS.MacOs ? metaKey : ctrlKey;
      if (key.toLowerCase() === 'w') {
        if (colorIndex > 0) {
          handleColorChange(colorOptions[colorIndex - 1]);
        }
      } else if (key.toLowerCase() === 's' && !IsCtrlPressed) {
        if (colorIndex < colorOptions.length - 1) {
          handleColorChange(colorOptions[colorIndex + 1]);
        }
      }
    },
    [handleColorChange, color, colorOptions]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('labelschanged', { bubbles: true, detail: { labels: labelsArray } }));
  }, [labelsArray]);

  return (
    <Container>
      <Molecules.Collapsible defaultOpen label={<BoldLabel>{t('project.label.tools.labels')}</BoldLabel>}>
        <ColorList>
          {colorOptions.map(colorOption => (
            <ColorItem
              key={colorOption.id}
              selected={color?.id === colorOption.id}
              onClick={() => handleColorChange(colorOption)}
            >
              <ColorInfo>
                <ColorIcon>{colorIconSelector(colorOption)}</ColorIcon>
                <ColorLabel>{labelInfo(colorOption.id).labelName}</ColorLabel>
              </ColorInfo>
            </ColorItem>
          ))}
        </ColorList>
      </Molecules.Collapsible>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 1rem;
  padding: 0.5rem;
`;

const BoldLabel = styled.span`
  font-weight: bold;
`;
const ColorList = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const ColorItem = styled.div<{ selected: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#E3F1FC' : 'transparent')};
  padding: 0.8rem 0.5rem;
  border-bottom: 1px solid #d3d3d3;

  &:last-child {
    border-bottom: none;
  }
`;
const ColorInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
`;

const ColorIcon = styled.div`
  margin-right: 0.5rem;
`;
const ColorLabel = styled.span`
  font-size: inherit;
`;
